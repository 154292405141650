import transaction from "./Transactions/entityConf";
import issues from "./Issues/entityConf";
import issueCharts from "./Issues/Charts/entityConf";
import transactionCharts from "./Transactions/Charts/entityConf";
import customer from "./Customer/entityConf";
import customerFeatures from "./CustomerFeature/entityConf";
import egateDepartments from "./Customer/Egate/entityConf";
import container from "./WasteContainers/entityConf";
import containerPaged from "./WasteContainers/entityConfPaged";
import contract from "./Contracts/entityConf";
import fractionConfigurationGroup from "./FractionConfigurationGroup/entityConf";
import intervalConfigurationGroup from "./IntervalConfigurationGroup/entityConf";
import regionCodeConfigurationGroup from "./RegionCodeGroup/entityConf";
import cardReaderGroup from "./CardReaderGroup/entityConf";
import regionCode from "./RegionCode/entityConf";
import user from "./User/entityConf";
import profile from "./Profile/entityConf";
import devices from "./Devices/entityConf";
import permissionGroup from "./PermissionGroups/entityConf";
import truck from "./Truck/entityConf";
import truckBody from "./TruckBody/entityConf";
import liftingSystem from "./LiftingSystem/entityConf";
import truckType from "./TruckType/entityConf";
import address from "./Address/entityConf";
import appMessage from "./AppMessage/entityConf";
import endTest from "./EndTest/entityConf";
import product from "./Product/entityConf";
import fillLevelSensor from "./FillLevelSensor/entityConf";
import fillLevelSensorType from "./FillLevelSensorType/entityConf";
import hardwareUnit from "./HardwareUnit/entityConf";
import firmware from "./Firmware/entityConf";
import cards from "./nfcCards/entityConf";
import dashboard from "./Dashboard/entityConf";
import serviceCockpit from "./ServisCockpit/entityConf";
import containerEmptying from "./ContainerEmptying/entityConf";
import nfcCard from "./nfcCards/entityConf";
import nfcCardBlacklist from "./nfcCardsBlackList/entityConf";
import emptyingDetection from "./EmptyingDetection/entityConf";
import taskList from "./TaskList/entityConf";
import loRaSettings from "./LoRaSettings/entityConf";
import mifareDataReadFrame from "./MifareDataReadFrame/entityConf";
import largeList from "./LargeList/entityConf";
import serviceCalls from "./ServiceCalls/entityConf";
import hardwareCommands from "./HardwareCommands/entityConf";
import containersAccessGroup from "./ContainerAccessGroup/entityConf";
import fillContainers from "./FillContainers/entityConf";
import technician from "./Technician/entityConf";
import serviceTaskCategory from "./ServiceTaskCategory/entityConf";
import serviceTaskCause from "./ServiceTaskCause/entityConf";
import serviceTaskMaterial from "./ServiceTaskMaterial/entityConf";
import serviceTask from "./ServiceTasks/entityConf";
import serviceAppEvents from "./ServiceAppEvents/entityConf";
import lockTypeAliases from "./LockTypeAlias/entityConf";
import systemOperationLogs from "./SystemOperationLog/entityConf";
import socialServicesRunners from "./SocialServiceRunners/entityConf";

import serviceTaskSolution from "./ServiceTaskSolution/entityConf";
import flows from "./Flows/entityConf";
import socialServiceActions from "./SocialServiceAction/entityConf";
import hardwareUnitPaged from "./HardwareUnit/entityConfPaged";
import emptyingDetectionLogs from "./EmptyingDetectionLogs/entityConf";
import biDashboardsManagement from "./BIDashboardManagement/entityConf";
import biDashboards from "./BIDashboards/entityConf";

export default {
  dashboard,
  serviceCockpit,
  transaction,
  transactionCharts,
  issues,
  issueCharts,
  containerEmptying,
  nfcCard,
  nfcCardBlacklist,
  customer,
  customerFeatures,
  container,
  containerPaged,
  contract,
  user,
  profile,
  devices,
  fractionConfigurationGroup,
  intervalConfigurationGroup,
  regionCodeConfigurationGroup,
  cardReaderGroup,
  regionCode,
  permissionGroup,
  truck,
  liftingSystem,
  truckType,
  truckBody,
  address,
  appMessage,
  endTest,
  product,
  fillLevelSensor,
  fillLevelSensorType,
  hardwareUnit,
  egateDepartments,
  firmware,
  cards,
  emptyingDetection,
  taskList,
  loRaSettings,
  mifareDataReadFrame,
  largeList,
  serviceCalls,
  hardwareCommands,
  containersAccessGroup,
  fillContainers,
  technician,
  serviceTaskCategory,
  serviceTaskCause,
  serviceTaskMaterial,
  serviceAppEvents,
  serviceTask,
  lockTypeAliases,
  systemOperationLogs,
  socialServicesRunners,
  serviceTaskSolution,
  flows,
  socialServiceActions,
  hardwareUnitPaged,
  biDashboards,
  biDashboardsManagement,
  emptyingDetectionLogs,
};
