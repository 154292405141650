import { TFunction } from "i18next";

export interface Dictionary<T> {
  [Key: number]: T;
}
export function required(t: TFunction, value = true, message?: string) {
  return {
    required: value,
    message: message ?? t("Required"),
  };
}

export function rules(t: TFunction, requiredParam: boolean) {
  if (requiredParam) {
    return [required(t)];
  } else {
    return [];
  }
}
