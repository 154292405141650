import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

const conf: EntityConfig = {
  ...entityFactory("truck-body"),
  endpoint: "truck-bodies",
  roleView: [ROLE.TRUCK_VIEW, ROLE.TRUCK_ADMIN],
  roleAdmin: [ROLE.TRUCK_ADMIN],
  singularName: (t) => t("Truck body"),
  name: (t) => t("Truck Bodies"),
  syncCustomerFilter: true,
};

export default conf;
