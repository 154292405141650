import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { minutesToUTCOffset } from "./utils";

const locale = window.navigator.language;
dayjs.locale(locale);
dayjs.extend(utc);

export const fullTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const shortTimeFormat = "YYYY-MM-DD HH:mm";
export const shortTimeFormatMachine = "YYYYMMDDHHmm";
export const onlyDateFormat = "YYYY-MM-DD";
export const timestampFormat = "YYYY-MM-DD-HH-mm";
export const onlyTimeFormat = "HH:mm:ss";
export const onlyTimeFormatNoSec = "HH:mm";

export function normalizeTimeFormatFixed(date: dayjs.Dayjs) {
  return date.set("year", 1900).set("month", 1).set("date", 1).set("second", 0).set("millisecond", 0);
}
export function normalizeTimeFormatNow(date: dayjs.Dayjs) {
  return date
    .set("year", dayjs().year())
    .set("month", dayjs().month())
    .set("date", dayjs().date())
    .set("second", 0)
    .set("millisecond", 0);
}

export function shortFormatUTCToLocal(date?: string | null): string | undefined {
  if (!date) {
    return undefined;
  }
  return dayjs.utc(date).local().format(shortTimeFormat);
}

export function dateOnlyFormatUTCToLocal(date?: string | null): string | undefined {
  if (!date) {
    return undefined;
  }
  return dayjs.utc(date).local().format(onlyDateFormat);
}

export function timeOnlyFormatUTCToLocal(date?: string | null): string | undefined {
  if (!date) {
    return undefined;
  }
  return dayjs(date).local().format(onlyTimeFormatNoSec);
}

export function fullFormatUTC(date: Date | undefined | string) {
  if (!date) {
    return undefined;
  }
  return dayjs.utc(date, fullTimeFormat).format(fullTimeFormat);
}

export function fullFormatUtcToLocal(date: Date | undefined | string) {
  if (!date) {
    return undefined;
  }

  return dayjs.utc(date).local().format(fullTimeFormat);
}

export function fullTimestampFormat(date: Date | undefined | string) {
  if (!date) {
    return undefined;
  }
  return dayjs.utc(date).local().format(timestampFormat);
}

export function onlyTime(date: Date | undefined) {
  if (!date) {
    return undefined;
  }
  return dayjs(date).local().format(onlyTimeFormat);
}

export function onlyTimeNoSecUTC(date: Date | undefined | string) {
  if (!date) {
    return undefined;
  }
  return dayjs.utc(date, fullTimeFormat).format(onlyTimeFormatNoSec);
}

export function toDate(d?: dayjs.Dayjs | null): string | undefined {
  if (d?.isValid()) {
    return d?.format();
  }
  return undefined;
}

export function formatToUtc(d?: dayjs.Dayjs | string): string | undefined {
  if (typeof d === "string") {
    const parsedDate = dayjs(d);
    if (!parsedDate.isValid()) {
      return undefined;
    }
    return parsedDate.utc().format(shortTimeFormat);
  }
  if (d?.isValid()) {
    return d?.utc().format(shortTimeFormat);
  }
  return undefined;
}

/**
 * Get correct local ime form backend stored time only datetime (example 0001-01-01 00:00:00)
 * @param time
 */
export function getLocalTimeFromTimeOnly(time: string) {
  return dayjs
    .utc(time?.replace("0001", "1900"))
    .set("year", 1900)
    .set("date", 1)
    .set("month", 1)
    .set("second", 0)
    .set("millisecond", 0)
    .local();
}

export function getUtcOffsetMinutes(tz?: string) {
  if (!tz) {
    return undefined;
  }
  const offset = dayjs().tz(tz).utcOffset();

  return minutesToUTCOffset(offset) + "  " + tz;
}
