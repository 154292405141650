import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("product"),
  endpoint: "products",
  roleView: [ROLE.PRODUCT_VIEW, ROLE.PRODUCT_ADMIN],
  roleAdmin: [ROLE.PRODUCT_VIEW, ROLE.PRODUCT_ADMIN],
  baseRoute: "product",
  singularName: (t) => t("Product"),
  name: (t) => t("Products"),
  infiniteNormalizer: (value) => {
    return {
      ...value,
      results: value.results.map((v) => ({
        ...v,
        id: v.id,
        name: v.partNumber + " | " + v.variant + " | " + v.type,
      })),
    };
  },
};

export default conf;
