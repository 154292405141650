import { TFunction } from "i18next";
import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { HardwareUnitFilter } from "../eGate-API";

const conf: EntityConfig<HardwareUnitFilter> = {
  ...entityFactory("hardware-unit"),
  endpoint: "hardware-units",
  roleView: [ROLE.HARDWARE_UNIT_VIEW, ROLE.HARDWARE_UNIT_ADMIN],
  roleAdmin: [ROLE.HARDWARE_UNIT_ADMIN],
  singularName: (t: TFunction) => t("Hardware unit"),
  name: (t: TFunction) => t("Hardware units"),
  initialFilter: {
    assignment: "all",
  },
  syncCustomerFilter: true,
};

export default conf;
