import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("region-code-preset"),
  endpoint: "region-codes-configuration-groups",
  roleView: [ROLE.REGION_CODE_VIEW, ROLE.REGION_CODE_ADMIN],
  roleAdmin: [ROLE.REGION_CODE_ADMIN],
  singularName: (t) => t("Region Code preset"),
  name: (t) => t("Region Code presets"),
  syncCustomerFilter: true,
  dependencies: ["container"],
};

export default conf;
