import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("region-code"),
  endpoint: "region-codes",
  roleView: [ROLE.REGION_CODE_VIEW, ROLE.REGION_CODE_ADMIN],
  roleAdmin: [ROLE.REGION_CODE_ADMIN],
  singularName: (t) => t("Region code"),
  name: (t) => t("Region codes"),
  syncCustomerFilter: true,
};

export default conf;
