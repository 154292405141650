import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("mifare-data-read-frame"),
  endpoint: "mifare-data-read-frame",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  singularName: (t) => t("Mifare data read frame"),
  name: (t) => t("Mifare data read frames"),
  syncCustomerFilter: true,
};
export default conf;
