import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("large-list"),
  endpoint: "large-lists",
  roleView: [ROLE.LARGE_LIST_VIEW, ROLE.LARGE_LIST_ADMIN],
  roleAdmin: [ROLE.LARGE_LIST_ADMIN],
  singularName: (t) => t("Large list"),
  name: (t) => t("Large lists"),
  syncCustomerFilter: true,
};
export default conf;
