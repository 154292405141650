import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { usersToMemberEdit } from "../helpers/utils";

const conf: EntityConfig = {
  ...entityFactory("user"),
  endpoint: "users",
  roleView: [ROLE.USER_VIEW, ROLE.USER_ADMIN],
  roleAdmin: [ROLE.USER_ADMIN],
  singularName: (t) => t("User"),
  name: (t) => t("Users"),
  infiniteNormalizer: (value) => ({
    rowCount: value.rowCount,
    first: value.first,
    results: usersToMemberEdit(value.results ?? []),
  }),
  sendCulture: true,
  dependencies: ["contract", "profile"],
  syncCustomerFilter: true,
};

export default conf;
