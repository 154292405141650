import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { rangeFilterUTC } from "../helpers/utils";

const conf: EntityConfig = {
  ...entityFactory("system-operations-log"),
  endpoint: "system-operations-log",
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN, ROLE.EMZ_DEVELOPER],
  roleAdmin: [ROLE.CUSTOMER_ADMIN, ROLE.EMZ_DEVELOPER],
  baseRoute: "system-operations-log",
  singularName: (t) => t("System Operations Log"),
  name: (t) => t("System Operations Logs"),
  syncFilterWithURL: true,
  sendCulture: true,
  initialFilter: {
    sortBy: "timestamp",
    sortOrder: "descend",
  },
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
};

export default conf;
