import styled from "styled-components";
import { emzPrimaryColor } from "./helpers/utils";

const FullPageSpinnerStyler = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000;
  background-color: rgba(255, 255, 255, 0.21);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.glass {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
  }

  .ant-spin {
    .ant-spin-dot-item {
      background-color: ${emzPrimaryColor} !important;
    }
  }
`;

export default FullPageSpinnerStyler;
