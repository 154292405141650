import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { rangeFilterUTC } from "../helpers/utils";
import dayjs from "dayjs";

const conf: EntityConfig = {
  ...entityFactory("issue"),
  endpoint: "issues",
  roleView: [ROLE.ISSUE_VIEW, ROLE.ISSUE_ADMIN],
  roleAdmin: [ROLE.ISSUE_ADMIN],
  baseRoute: "issue",
  singularName: (t) => t("Container issue"),
  name: (t) => t("Container issues"),
  syncFilterWithURL: true,
  sendCulture: true,
  initialFilter: {
    sortBy: "reportedOn",
    sortOrder: "descend",
    from: dayjs().subtract(1, "day").format(),
    to: dayjs().format(),
  },
  disableCache: true,
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
  disableHotKeys: true,
};

export default conf;
