import { fetchEGate } from "../eGateApi";
import { toJson } from "../apiHelpers";
import { PartialListDto, PasswordChangeResultDto, UserDto, UserListDto, UserStatusListDto } from "../../eGate-API";
import { isValueUsed } from "./UniquenesApi";

/**
 * Gets whether user's email is already used
 * @param value
 * @param message
 */
export const isEmailUsed = (value: string, message: string): Promise<void> =>
  isValueUsed<UserDto, string>("users", "email", value, message);

export function changePassword(value: any) {
  return toJson<PasswordChangeResultDto>(fetchEGate("POST", "users/password", value));
}

/**
 * Verifies that password was not pawned
 * @param passwordHash SHA1 hash of the password
 * @returns Number of times the password was pawned
 */
export async function passwordPawnedCount(passwordHash: string): Promise<number> {
  const start = passwordHash.substring(0, 5);
  const rest = passwordHash.substring(5).toUpperCase();
  try {
    const response = await fetch(`https://api.pwnedpasswords.com/range/${start}`, {
      headers: {
        "Add-Padding": "true",
      },
    });
    const data: string = await response.text();
    const lines = data.split("\n");
    const result = lines.find((r) => r.startsWith(rest))?.split(":");
    if (result && result[1]) {
      return parseInt(result[1], 10);
    }
    return 0;
  } catch (err) {
    // On error, just return 0
    return 0;
  }
}
export async function getInfiniteList(
  endpoint: string,
  {
    skip,
    max,
    fulltext,
    customerId,
  }: {
    skip: number;
    max: number;
    fulltext?: string;
    customerId?: number;
  },
  initialValue?: UserListDto
) {
  const response = await toJson<PartialListDto<UserStatusListDto>>(
    fetchEGate("GET", endpoint, null, {
      max,
      skip,
      fulltext,
      customerId,
    })
  );
  return {
    ...response,
    rowCount: initialValue ? response.rowCount + 1 : response.rowCount,
    results: [
      { name: initialValue?.username, id: initialValue?.id },
      ...response.results.map((i) => ({ ...i, name: i.username })),
    ].filter((i) => i?.id) as UserListDto[],
  };
}

export async function getUserInfiniteList(
  params: { skip: number; max: number; fulltext?: string; customerId?: number },
  initialValue?: UserListDto
) {
  return getInfiniteList("users/list", params, initialValue);
}

export async function getTechnicianInfiniteList(
  params: { skip: number; max: number; fulltext?: string; customerId?: number },
  initialValue?: UserListDto
) {
  return getInfiniteList("technician/list", params, initialValue);
}

export async function getSocialServiceRunnersList(
  params: { skip: number; max: number; fulltext?: string; customerId?: number },
  initialValue?: UserListDto
) {
  return getInfiniteList("social-service-runners/list", params, initialValue);
}
