import { Button, Dropdown, Modal } from "antd";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import RawHtml from "../Auth/RawHtml";
import useApp from "../hooks/useApp";
import FaIcon from "./FaIcon";
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons";
import Version from "./Version";

const Style = styled.div`
  width: calc(100% - 100px);
  button {
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary) !important;
  }
`;

export default function LegalStuff(props: { noWrapper?: boolean }) {
  const { t, i18n } = useTranslation();
  const { appData } = useApp();

  function legal() {
    showHtml("legalnotice");
  }

  function eula() {
    showHtml("eula_web");
  }

  function privacypolicy() {
    showHtml("privacypolicy_web");
  }

  function showHtml(endpoint: string) {
    fetch(`/legal/${endpoint}?culture=${i18n.language}`)
      .then((value) => value.text())
      .then((html) => {
        Modal.info({
          title: "",
          width: "1000px",
          okButtonProps: { style: { display: "none" } },
          content: <RawHtml html={html} />,
          maskClosable: true,
        });
      });
  }

  const collapsed = appData?.collapsed;
  const noWrapper = props?.noWrapper;

  const content = [
    {
      key: "legal",
      label: (
        <div key={"legal"} onClick={legal}>
          {t("Legal notice")}
        </div>
      ),
    },
    {
      key: "eula",
      label: (
        <div key={"eula"} onClick={eula}>
          {t("EULA")}
        </div>
      ),
    },
    {
      key: "privacypolicy",
      label: (
        <div key={"privacypolicy"} onClick={privacypolicy}>
          {t("Privacy policy")}
        </div>
      ),
    },
    {
      key: "version",
      label: <Version />,
    },
  ];

  if (collapsed) {
    return (
      <Dropdown className={classNames("legal-wrapper", { collapsed })} menu={{ items: content }} trigger={["hover"]}>
        <Button icon={<FaIcon icon={faEllipsisH} size={"2x"} />} type={"link"} />
      </Dropdown>
    );
  }

  const buttons = (
    <>
      <Button type={"link"} onClick={legal}>
        {t("Legal notice")}
      </Button>
      <Button type={"link"} onClick={eula}>
        {t("EULA")}
      </Button>
      <Button type={"link"} onClick={privacypolicy}>
        {t("Privacy policy")}
      </Button>
    </>
  );

  if (noWrapper) {
    return buttons;
  }
  return <Style className={classNames("legal-wrapper", { collapsed })}>{buttons}</Style>;
}
