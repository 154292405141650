import styled from "styled-components";

const BoxWrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: none;
  border-radius: var(--corner);
  margin-bottom: var(--gap);
  overflow-x: hidden;

  &.full-height {
    height: 100%;
  }

  > .box-content {
    padding: var(--gap);
  }

  &.table-mode {
    > .box-content {
      padding-bottom: 0;
    }

    border-radius: var(--corner) var(--corner) 0 0;
  }

  .box-content > .view-row:first-child {
    margin-top: var(--gap-extra-small);
  }

  .box-content > .ant-form-item:first-child {
    margin-top: var(--gap-extra-small);
  }

  .ant-form-item:last-of-type {
    margin-bottom: 0;
  }

  &.round-bottom {
    border-radius: 0 0 var(--corner) var(--corner);
  }

  &.round-top {
    border-radius: var(--corner) var(--corner) 0 0;
  }

  &.top-padding {
    .box-content {
      padding-top: var(--margin-small);
    }
  }

  .border-div-bottom {
    margin-top: -1px;
  }

  &.middle {
    border-radius: 0 0 0 0;
  }

  &.plain {
    h3 {
      font-size: 1rem;
      color: black;
    }

    p {
      color: black;
    }
  }

  &.content-center {
    > .box-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.full-width {
    width: 100%;

    padding-left: 0;
    padding-right: 0;

    > .box-content {
      padding-left: 0;
      padding-right: 0;
    }

    .ant-pagination {
      margin-right: 1rem;
    }
  }
  .box-footer {
    border-top: 1px solid #f0f0f0;
  }
`;

export { BoxWrapper };
