import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("nfc-card-blacklist"),
  endpoint: "nfc-card-blacklists",
  roleView: [ROLE.REGION_CODE_VIEW, ROLE.REGION_CODE_ADMIN],
  roleAdmin: [ROLE.REGION_CODE_ADMIN],
  singularName: (t) => t("Digi blacklist"),
  name: (t) => t("Digi blacklist"),
  syncCustomerFilter: true,
  dependencies: ["nfc-card"],
};

export default conf;
