import { fetchEGate } from "../helpers/eGateApi";
import { Favourites } from "./useApp";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export function useFavourites({
  enabled = true,
}): [Favourites | undefined, (payload: any) => void, isFetching: boolean] {
  const queryClient = useQueryClient();
  const favourites = useQuery({
    queryKey: ["favourites"],
    queryFn: async () => {
      const res = await fetchEGate("GET", "users/favourites");
      if (res.status === 200) {
        const text = await res.text();
        return JSON.parse(JSON.parse(text));
      }
      return {};
    },
    retry: false,
    enabled: enabled,
  });

  function setFavouritesData(payload: any) {
    queryClient.setQueryData(["favourites"], payload);
  }

  return [favourites.data, setFavouritesData, favourites.isFetching];
}
