import React from "react";

import Version from "../components/Version";

import TopbarLanguage from "../containers/Topbar/TopbarLanguage";
import LegalStuff from "../components/LegalStuff";
import styled from "styled-components";
import { emzPrimaryColor } from "../helpers/utils";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import emz from "../image/emz.svg";

const Style = styled.div`
  &.bottom-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 992px) {
      position: relative;
      margin-top: 100px;
    }

    .ant-btn-link {
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #03af5c;
      }
    }

    .bottom-bar-content {
      width: min(600px, 90%);
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .app-version {
        width: 80px;
        text-align: right;
      }
      .legal-wrapper {
        display: flex;
        justify-content: center;
      }

      .language-button {
        border-radius: 10px;

        svg {
          color: ${emzPrimaryColor} !important;
        }
      }
    }

    .ant-btn-link {
      padding: 0;
      margin-left: 30px;
    }
    .ant-dropdown-trigger {
      margin-left: 30px;
    }
  }
  &.bottom-bar-mobile {
    flex-direction: column;
    justify-content: normal;
    .bottom-bar-content {
      flex-direction: column;
      justify-content: normal;
    }
    .version-language-wrapper {
      width: 100%;
      flex: 10;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
    .legal-stuff-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex: 10;
      align-items: center;
    }
    .ant-btn-link {
      padding: 0;
      margin-left: 0;
    }
    .ant-dropdown-trigger {
      margin-left: 0;
    }
  }
`;

export default function () {
  const { sm } = useBreakpoint();
  if (!sm) {
    return (
      <Style className={"bottom-bar bottom-bar-mobile"}>
        <div className={"bottom-bar-content"}>
          <div className={"version-language-wrapper"}>
            <Version />
            <TopbarLanguage />
          </div>
          <div className={"legal-stuff-wrapper"}>
            <LegalStuff noWrapper />
            <img alt={"company logo"} src={emz} width={60} />
          </div>
        </div>
      </Style>
    );
  }

  return (
    <Style className={"bottom-bar"}>
      <div className={"bottom-bar-content"}>
        <img alt={"company logo"} className={"mr-2"} src={emz} width={70} />
        <Version />
        <LegalStuff noWrapper />
        <TopbarLanguage />
      </div>
    </Style>
  );
}
