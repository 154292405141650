import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";

const conf: EntityConfig = {
  ...entityFactory("nfc-card"),
  roleView: [ROLE.CONTRACT_VIEW, ROLE.CONTRACT_ADMIN],
  roleAdmin: [ROLE.CONTRACT_ADMIN],
  endpoint: "nfc-cards",
  name: (t) => t("Keys"),
  singularName: (t) => t("Key"),
  syncCustomerFilter: true,
  infiniteNormalizer: (value) => {
    return {
      ...value,
      results: value.results.map((v) => ({
        ...v,
        id: v.id,
        name: v.id,
      })),
    };
  },
};
export default conf;
