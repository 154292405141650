import { Button, Dropdown } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import useServerInfo from "../../hooks/useServerInfo";
import FaIcon from "../../components/FaIcon";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import ViewSpinner from "../../components/ViewSpinner";
import useLanguageMenu from "./useLanguageMenu";

const TopbarLanguage = () => {
  const { i18n } = useTranslation();

  const { data, isFetching } = useServerInfo();

  const languages = data?.languages;

  const items = useLanguageMenu();

  if (isFetching) {
    return <ViewSpinner isFetching />;
  }

  const languageLabel = languages?.find((i) => i.id === i18n.language);

  const title = (
    <div className={"icon-wrapper-circle language-label"}>
      <Button className={"language-button"} ghost type={"primary"}>
        {languageLabel?.nativeName}
        <FaIcon icon={faAngleDown} size={"lg"} />
      </Button>
    </div>
  );

  return (
    <Dropdown className={"dropdown"} menu={{ items: items?.children as any[] }} trigger={["click"]}>
      {title}
    </Dropdown>
  );
};

export default TopbarLanguage;
