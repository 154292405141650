import { Button } from "antd";
import React from "react";
import Logo from "../../components/utility/Logo";
import Version from "../../components/Version";
import LegalStuff from "../../components/LegalStuff";
import SidebarWrapper from "./Sidebar.style";
import useApp from "../../hooks/useApp";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import emz from "../../image/emz.svg";
import Menu from "./Menu";
import FaIcon from "../../components/FaIcon";

/**
 * Application side bar
 */
function Sidebar() {
  const { appData, toggleCollapsed } = useApp();

  const isCollapsed: boolean = appData?.collapsed ?? false;

  function toggleSidebar() {
    if (isCollapsed) {
      toggleCollapsed();
    }
  }

  return (
    <SidebarWrapper
      breakpoint="lg"
      className={isCollapsed ? "collapsed" : "not-collapsed"}
      collapsed={isCollapsed}
      collapsible={true}
      theme={"light"}
      trigger={null}
      width="240"
      onBreakpoint={(c: boolean) => {
        toggleCollapsed(c);
      }}
      onClick={toggleSidebar}
    >
      <div className={"top-items-wrapper"}>
        <Button
          className={"burger"}
          icon={<FaIcon icon={faBars} size={"lg"} />}
          type="text"
          onClick={() => toggleCollapsed(true)}
        />
        <Logo collapsed={isCollapsed} />
      </div>

      <Menu />

      <LegalStuff />
      {!isCollapsed && <Version />}
      <img alt={"company logo"} className={"company-logo"} src={emz} width={isCollapsed ? 60 : 80} />
    </SidebarWrapper>
  );
}

export default Sidebar;
