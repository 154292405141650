import React from "react";
import * as Sentry from "@sentry/browser";
import CrashView from "./CrashView";
import ViewSpinner from "./components/ViewSpinner";

function hasLoadError(error: Error) {
  return (
    error?.message?.indexOf("Failed to fetch dynamically imported") > -1 ||
    error?.message?.indexOf("error loading dynamically imported module") > -1
  );
}

type State = {
  repeat: number;
  sentryId?: string;
  hasError: boolean;
  hasCacheError: boolean;
  developmentError?: string;
};
export default class ErrorBoundary extends React.Component<any, State> {
  public state: State = {
    hasError: false,
    developmentError: undefined,
    sentryId: undefined,
    hasCacheError: false,
    repeat: 0,
  };

  public static getDerivedStateFromError(error: Error) {
    if (hasLoadError(error)) {
      return { hasCacheError: true };
    }
    return { hasError: true, developmentError: JSON.stringify(error.message) };
  }
  public componentDidCatch(error: Error) {
    if (hasLoadError(error)) {
      this.setState((state) => ({ repeat: state?.repeat + 1 }));
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      console.error(error);

      const sentryId = Sentry.captureException(error);
      this.setState({ sentryId });
    }
  }
  public render() {
    if (this.state.hasCacheError) {
      return <ViewSpinner isFetching={true} />;
    }

    if (this.state.hasError) {
      return <CrashView developmentError={this.state.developmentError} sentryId={this.state.sentryId} />;
    }
    return this.props.children;
  }
}
