import React, { ReactNode } from "react";
import { BoxSubTitle, BoxTitle } from "./boxTitle.style";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { emzPrimaryColor } from "../../helpers/utils";

const BoxTitleWrapper = styled.div`
  width: 100%;

  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-evenly;
  color: ${emzPrimaryColor};
  .fa-lg {
    transform: scale(1.18);
  }
  &.boxy {
    border-radius: 0;
  }
  .box-title-content {
    padding-top: var(--gap-medium);
    display: flex;
    align-items: center;
  }
  .border-div {
    margin-left: 55px;
    width: calc(100% - 55px - 55px);

    border-bottom: 1px solid #ebebeb;
  }
  .box-icon {
    margin-left: 20px;
    color: ${emzPrimaryColor};
  }
`;

export default (props: {
  title?: React.ReactNode;
  subtitle?: string;
  icon?: IconProp;
  inner?: boolean;
  plain?: boolean;
  middle?: boolean;
  underline?: boolean;
  extra?: ReactNode;
}) => (
  <BoxTitleWrapper className={props.inner ? "boxy" : ""}>
    {!props.plain && props.middle && <div className={"border-div"} />}

    <div className={"box-title-content"}>
      {props.icon && <FontAwesomeIcon className={"box-icon"} icon={props.icon} size={"lg"} />}

      {props.title ? <BoxTitle className="isoBoxTitle"> {props.title} </BoxTitle> : ""}
      {props.subtitle ? <BoxSubTitle className="isoBoxSubTitle"> {props.subtitle} </BoxSubTitle> : ""}
      {props.extra}
    </div>
    {props.underline && <div className={"border-div border-div-bottom"} />}
  </BoxTitleWrapper>
);
