import React from "react";
import { appVersion } from "../version";
import styled from "styled-components";

const VersionStyle = styled.div`
  font-family: Manrope, serif;
  font-size: 14px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 300;
  color: #636262;
  width: 100%;
  text-align: left;
`;

/**
 * Displays version
 */
const Version = () => {
  return <VersionStyle className={"app-version"}>v. {appVersion}</VersionStyle>;
};

export default Version;
