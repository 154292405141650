import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ContainerAddressListDto } from "../eGate-API";
import { formatFullAddress } from "./FullAddress";

const conf: EntityConfig = {
  ...entityFactory("container-address"),
  endpoint: "container-addresses",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  infiniteNormalizer: (value) => {
    return {
      ...value,
      results: value.results.map((v: ContainerAddressListDto) => ({
        name: formatFullAddress(v),
        description: v.name,
        addressName: v.name,
        id: v.id,
      })),
    };
  },
  singularName: (t) => t("Address"),
  name: (t) => t("Address"),
  syncCustomerFilter: true,
};

export default conf;
