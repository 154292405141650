import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { shortTimeFormat } from "../helpers/timeUtils";
import dayjs from "dayjs";

export function rangeFilterUTC(filter: { from?: string; to?: string }) {
  return {
    ...filter,
    createdTo: filter?.to ? dayjs(filter?.to).utc().format(shortTimeFormat) : undefined,
    createdFrom: filter?.from ? dayjs(filter?.from).utc().format(shortTimeFormat) : undefined,
  };
}

const conf: EntityConfig = {
  ...entityFactory("hardware-commands"),
  endpoint: "hardware-commands",
  roleView: [ROLE.EMZ_DEVELOPER],
  roleAdmin: [ROLE.EMZ_DEVELOPER],
  baseRoute: "hardware-commands",
  singularName: (t) => t("Hardware Command"),
  name: (t) => t("Hardware Commands"),
  initialFilter: {
    sortBy: "receivedOn",
    sortOrder: "descend",
  },
  filterFormatter: rangeFilterUTC,
};

export default conf;
