import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("container-paged"),
  endpoint: "containers/list-paged",
  listEndpoint: "containers/list",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  singularName: (t) => t("Container"),
  name: (t) => t("Containers"),
  initialFilter: {
    status: "all",
  },
  syncCustomerFilter: true,
  dependencies: ["region-code"],
  syncFilterWithURL: true,
};

export default conf;
