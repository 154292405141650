import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { FillLevelSensorDto, FillLevelSensorListDto, PagedResultDto, PartialListDto } from "../eGate-API";

export const conf: EntityConfig = {
  ...entityFactory("fill-level-sensor"),
  endpoint: "fill-level-sensors",
  roleView: [ROLE.FILL_SENSOR_VIEW, ROLE.FILL_SENSOR_ADMIN],
  roleAdmin: [ROLE.FILL_SENSOR_ADMIN],
  baseRoute: "fill-level-sensor",
  singularName: (t) => t("Fill Level Sensor"),
  name: (t) => t("Fill Level Sensors"),
  sendCulture: true,
  infiniteNormalizer: (value: PartialListDto<FillLevelSensorListDto>) => {
    const results = value.results
      .filter((v) => v !== undefined)
      .map((v) => ({
        name: v.name + " | " + v?.type?.name,
        id: v.id,
        type: v.type,
      }));
    return {
      ...value,
      results,
    };
  },
  normalizer: (value: PagedResultDto<FillLevelSensorDto>) => {
    return {
      ...value,
      results: value.results.map((v: any) => ({
        ...v,
        name: v.name + " | " + v?.type?.name,
      })),
    };
  },
  syncCustomerFilter: true,
};

export default conf;
