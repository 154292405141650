import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("task-list"),
  endpoint: "reports",
  baseRoute: "task-list",
  roleView: [ROLE.CUSTOMER_ADMIN, ROLE.CUSTOMER_VIEW],
  roleAdmin: [ROLE.CUSTOMER_ADMIN],
  singularName: (t) => t("Task list"),
  name: (t) => t("Task lists"),
  syncFilterWithURL: true,
  syncCustomerFilter: true,
};

export default conf;
