import { Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { CustomerListDto } from "../../eGate-API";
import useModal from "antd/es/modal/useModal";
import FaIcon from "../../components/FaIcon";
import styled from "styled-components";
import GlobalCustomerSelectField from "./GlobalCustomerField";
import { useFavourites } from "../../hooks/userFavourites";
import { fetchEGate } from "../../helpers/eGateApi";

interface Props {
  onCustomerChange: (customer: CustomerListDto) => void;
  selectedCustomer?: Partial<CustomerListDto>;
  closeModal: () => void;
}

const Style = styled.div`
  white-space: nowrap;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    border-color 0.3s,
    background 0.3s;
  border-radius: var(--corner);
  span {
    line-height: 1.2rem;
  }
  cursor: pointer;
  div {
    flex: 1;
    padding: 0 8px;
  }
`;

function ModalForm(props: Props) {
  const [form] = Form.useForm();

  function onFinish(values: any) {
    props.onCustomerChange(values.customer);
    props.closeModal();
  }
  const isEditingPage = window.location.pathname.includes("edit");
  return (
    <Form
      form={form}
      id="form"
      initialValues={{ customer: props.selectedCustomer }}
      labelAlign={"left"}
      layout="vertical"
      scrollToFirstError
      onValuesChange={onFinish}
    >
      <Form.Item name={"customer"}>
        <GlobalCustomerSelectField all disabled={isEditingPage} />
      </Form.Item>
    </Form>
  );
}

const TopbarCustomers = () => {
  const { t } = useTranslation();

  const [modal, modalContext] = useModal();

  const [favourites, setFavouritesData] = useFavourites({ enabled: true });

  const sidebarFavourites = favourites?.sidebarFavourites ?? [];
  const selectedCustomer = favourites?.selectedCustomer;
  async function setSelectedCustomer(newSelectedCustomer: CustomerListDto) {
    const payload = {
      sidebarFavourites,
      selectedCustomer: newSelectedCustomer,
      id: "",
    };
    setFavouritesData(payload);
    await fetchEGate("PUT", "users/favourites", {
      data: JSON.stringify(payload),
    });
  }

  function showModal() {
    const modalInstance = modal.info({
      title: t("Select customer"),
      content: (
        <ModalForm closeModal={closeModal} selectedCustomer={selectedCustomer} onCustomerChange={setSelectedCustomer} />
      ),
      okButtonProps: { style: { display: "none" } },
      icon: undefined,
      closable: true,
      closeIcon: <FaIcon icon={faCircleXmark} />,
      onCancel: () => {
        closeModal();
      },
      transitionName: "",
      maskTransitionName: "",
    });
    function closeModal() {
      modalInstance.destroy();
    }
  }

  function getLabel() {
    if (selectedCustomer) {
      return selectedCustomer.name;
    }
    return t("All");
  }

  return (
    <Style key={"customer"} className={"gray-box primary"} data-cy={"language-switcher-language"}>
      {modalContext}
      <div
        onClick={() => {
          showModal();
        }}
      >
        <span>{t("Customer")}</span>: {getLabel()}
      </div>
    </Style>
  );
};

export default TopbarCustomers;
