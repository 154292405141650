import styled from "styled-components";
import FaIcon from "./FaIcon";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-light-svg-icons";

const Style = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  min-height: 2rem;
  gap: 8px;
  p {
    margin: 0;
    padding: 0;
  }
`;

interface Props {
  text: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
}

export default function (props: Props) {
  return (
    <Style className={props.className}>
      {!props.disabled && props.checked ? (
        <FaIcon color={"#31BB3F"} icon={faCircleCheck} size={"lg"} />
      ) : (
        <FaIcon icon={faCircleXmark} size={"lg"} />
      )}
      <p>{props.text}</p>
    </Style>
  );
}
