import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import dayjs from "dayjs";

const conf: EntityConfig = {
  ...entityFactory("emptying-detection-logs"),
  endpoint: "emptying-detection-logs",
  roleView: [ROLE.EMZ_DEVELOPER],
  roleAdmin: [ROLE.EMZ_DEVELOPER],
  singularName: (t) => t("Container emptying log"),
  name: (t) => t("Container emptying logs"),
  initialFilter: {
    sortBy: "timestamp",
    sortOrder: "descend",
    from: dayjs().startOf("month").subtract(1, "months").format(),
    to: dayjs().format(),
  },
  syncCustomerFilter: true,
};

export default conf;
