import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

const conf: EntityConfig = {
  ...entityFactory("firmware"),
  endpoint: "firmware",
  roleView: [ROLE.FIRMWARE_VIEW, ROLE.FIRMWARE_ADMIN],
  roleAdmin: [ROLE.FIRMWARE_ADMIN],
  singularName: (t) => t("Firmware"),
  name: (t) => t("Firmware"),
  optimistic: true,
  disableHotKeys: true,
  syncCustomerFilter: true,
};

export default conf;
