import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import dayjs from "dayjs";
import { shortTimeFormat } from "../helpers/timeUtils";
import { ContainerTransactionFilter } from "../eGate-API";

function formatStatus(multiple: number[]) {
  function getConfirmed() {
    if (multiple?.includes(0) && multiple?.includes(1)) {
      return undefined;
    } else if (multiple?.includes(0) && !multiple?.includes(1)) {
      return false;
    } else if (!multiple?.includes(0) && multiple?.includes(1)) {
      return true;
    }
  }

  return {
    confirmed: getConfirmed(),
    conflict: multiple?.includes(4) ? true : undefined,
    noContract: multiple?.includes(128) ? true : undefined,
  };
}

const conf: EntityConfig = {
  ...entityFactory("transactions"),
  endpoint: "container-transactions",
  roleView: [ROLE.TRANSACTION_VIEW],
  roleAdmin: [],
  singularName: (t) => t("Transaction"),
  name: (t) => t("Transactions"),
  syncFilterWithURL: true,
  disableCache: true,
  syncCustomerFilter: true,
  filterFormatter: ({ status, ...filter }: ContainerTransactionFilter & { status: any }) => {
    return {
      ...filter,
      receivedFrom: filter?.receivedFrom ? dayjs(filter?.receivedFrom).utc().format(shortTimeFormat) : undefined,
      receivedTo: filter?.receivedTo ? dayjs(filter?.receivedTo).utc().format(shortTimeFormat) : undefined,
      ...formatStatus(status),
    };
  },
  initialFilter: {
    sortBy: "receivedOn",
    sortOrder: "descend",
    from: dayjs().subtract(1, "week").format(shortTimeFormat),
    to: dayjs().format(shortTimeFormat),
  },
};

export default conf;
