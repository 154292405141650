import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("fraction-preset"),
  endpoint: "fraction-configuration-groups",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  dependencies: ["container"],
  singularName: (t) => t("Fraction"),
  name: (t) => t("Fractions"),
  syncCustomerFilter: true,
};

export default conf;
