import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("materials"), // Changed from 'service-task-causes'
  endpoint: "materials", // Changed from 'service-task-causes'
  roleView: [ROLE.SC_TASK_CATEGORY_VIEW, ROLE.SC_TASK_CATEGORY_ADMIN],
  roleAdmin: [ROLE.SC_TASK_CATEGORY_ADMIN],
  singularName: (t) => t("Material"), // Changed from 'Service task cause'
  name: (t) => t("Materials"), // Changed from 'Service task causes'
  syncCustomerFilter: true,
  dependencies: ["service-task"],
};
export default conf;
