import React, { useEffect } from "react";
import DOMPurify from "dompurify";
import styled from "styled-components";

const RawHTMLStyler = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

/**
 * Raw HTML
 */
function RawHtml(props: { html: string }) {
  const cleanHtml = DOMPurify.sanitize(props.html);

  const ref = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (ref) {
      ref.current && ref.current.scrollTo({ top: 0 });
    }
  }, [ref]);
  return <RawHTMLStyler ref={ref} dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
}

export default RawHtml;
