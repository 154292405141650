import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("lora-settings"),
  endpoint: "lora-settings",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  singularName: (t) => t("LoRa Settings"),
  name: (t) => t("LoRa Settings"),
  syncCustomerFilter: true,
};
export default conf;
