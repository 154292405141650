import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("service-task-causes"),
  endpoint: "service-task-causes",
  roleView: [ROLE.SC_TASK_CATEGORY_VIEW, ROLE.SC_TASK_CATEGORY_ADMIN],
  roleAdmin: [ROLE.SC_TASK_CATEGORY_ADMIN],
  singularName: (t) => t("Service task cause"),
  name: (t) => t("Service task causes"),
  syncCustomerFilter: true,
  dependencies: ["service-task"],
  sendCulture: true,
};
export default conf;
