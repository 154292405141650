import styled from "styled-components";
import { transition } from "../../config/style-util";
import Sider from "antd/es/layout/Sider";

const SidebarWrapper = styled(Sider)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 512;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  overflow-y: auto;
  overflow-x: hidden;
  transition: border-color 0s !important;
  background: white;

  .fak {
    color: var(--primary) !important;
  }

  .side-menu {
    margin-top: 24px;
  }

  a,
  svg,
  .ant-menu-title-content {
    color: var(--primary);
    text-overflow: initial !important;
    overflow: visible !important;
    font-weight: 500;
  }

  .ant-menu-submenu {
    transition: none;
  }

  .ant-menu-submenu:hover,
  .ant-menu-submenu-open {
    .fak {
      color: white !important;
      filter: brightness(0) invert(1);
    }
  }

  .ant-menu-item-selected:after {
    border: none;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected,
  .ant-menu-item:hover {
    border-radius: 0 var(--corner) var(--corner) 0 !important;
    background: var(--primary-light) !important;

    svg {
      color: white !important;
    }

    > .ant-menu-title-content {
      a {
        color: white !important;
      }
    }
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    transition: border-color 0s !important;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: var(--gap) !important;
  }

  .ant-menu-item-icon {
    width: 24px;
  }

  .ant-menu-submenu-active,
  .ant-menu-submenu-open,
  .ant-menu-submenu:hover {
    .ant-menu-submenu-title {
      background: var(--primary-light) !important;
      border-radius: 0 var(--corner) var(--corner) 0 !important;
      color: white !important;

      svg,
      span {
        ${transition(0)}

        color: white;
      }
    }

    .ant-menu-sub {
      background: white;

      .ant-menu-item {
        a {
          font-size: 14px;
        }
      }

      .ant-menu-item-selected,
      .ant-menu-item:hover {
        background: #daffed !important;

        span {
          a {
            color: var(--primary) !important;
          }
        }
      }
    }

    .ant-menu-submenu-expand-icon {
      svg {
        color: white !important;
      }
    }
  }

  .ant-menu-submenu-expand-icon {
    svg {
      color: var(--light-gray-secondary-text) !important;
    }
  }

  .top-items-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: var(--margin-small);

    .burger {
      margin-left: 10px;
    }

    .logo-wrapper {
      margin-left: 10px;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: 0;
  }

  .ant-layout-sider-children {
    width: 240px;
    display: flex;
    flex-direction: column;
    height: 100% !important;
  }

  .ant-menu-root {
    width: calc(100% - 12px);
  }

  .ant-menu-item-only-child {
    font-size: 15px;
  }

  .ant-menu-sub .ant-menu-item a {
    margin-left: 16px;
  }

  &.collapsed {
    .ant-menu-item {
      padding-right: 0px !important;
      display: flex;
      justify-content: center;
    }
    .ant-menu-title-content {
      display: none !important;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 0 !important;
    }

    .ant-menu-item-active,
    .ant-menu-item-selected,
    .ant-menu-item:hover {
      border-radius: var(--corner) !important;
    }

    .ant-menu-submenu-active,
    .ant-menu-submenu-open,
    .ant-menu-submenu:hover {
      .ant-menu-submenu-title {
        border-radius: var(--corner) !important;
      }
    }

    .ant-menu-item-active,
    .ant-menu-item-selected,
    .ant-menu-item:hover {
      border-radius: var(--corner);
    }

    .ant-layout-sider-children {
      width: 90px;
      height: 100% !important;

      .ant-menu-vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .top-items-wrapper {
        flex-direction: column;
        align-items: center;
        margin: 0;
        margin-top: var(--gap);

        .burger {
          margin-left: 0px;
        }

        .logo-wrapper {
          margin-left: 0;
          margin-top: var(--gap);

          img {
            width: 22px;
          }
        }
      }

      .ant-menu-item.top-link,
      .ant-menu-submenu-title {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
      }

      .-title-content,
      .ant-menu-title-content {
        display: none;
      }

      display: flex;
      justify-content: start;
      align-items: center;
      flex-direction: column;
    }

    .legal-wrapper {
      margin-left: 0;
    }

    .app-version {
      width: 100%;
      text-align: center;
    }
  }

  .dropdown-content {
    background: white !important;
  }

  .legal-wrapper,
  .company-logo,
  .app-version {
    margin-left: var(--margin-large);
  }

  &.collapsed {
    .company-logo {
      margin-left: 0;
    }
  }

  .company-logo {
    margin-top: auto;
    opacity: 80%;
    margin-bottom: 32px;
  }

  .app-version {
    width: calc(100% - 130px);
  }
`;

export default SidebarWrapper;
