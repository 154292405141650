import AppleSignin from "react-apple-signin-auth";
import { useTranslation } from "react-i18next";
import { linkEGate } from "../helpers/eGateApi";
import styled from "styled-components";

interface AppleLoginProps {
  clientId: string;
}

export const AppleSigninStyle = styled.div`
  .apple-auth-btn {
    border-radius: 16px !important;
    height: 32px !important;
    color: rgb(60, 64, 67) !important;
    border: 1px solid #dadce0 !important;
    font-size: 14px !important;
    min-width: 196px !important;
  }
`;

export default function AppleLogin(props: AppleLoginProps) {
  const { t } = useTranslation();

  const redirectUri = linkEGate("authorize/apple", null, false, "v2");

  return (
    <AppleSigninStyle>
      <AppleSignin
        authOptions={{
          /** Client ID - eg: 'com.example.com' */
          clientId: props.clientId,
          /** Requested scopes, seperated by spaces - eg: 'email name' */
          scope: "email name",
          /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
          redirectURI: redirectUri,
          /** State string that is returned with the apple response */
          state: "state",
          /** Nonce */
          nonce: "nonce",
          /** Uses popup auth instead of redirection */
          usePopup: false,
        }}
        /** General props */
        skipScript={false} // default = undefined
        /** Removes default style tag */
        noDefaultStyle={false}
        /** Allows to change the button's children, eg: for changing the button text */
        buttonExtraChildren={t("Continue with Apple")}
        /** Extra controlling props */
        /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
        uiType="light"
        /** className */
        className="apple-auth-btn" // default = undefined
        /** Skips loading the apple script if true */
        onSuccess={(response: any) => console.log(response)} // default = undefined
        /** Called upon signin error */
        onError={(error: any) => console.error(error)}
      />
    </AppleSigninStyle>
  );
}
