import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("lifting-system"),
  endpoint: "lifting-systems",
  roleView: [ROLE.TRUCK_VIEW, ROLE.TRUCK_ADMIN],
  roleAdmin: [ROLE.TRUCK_ADMIN],

  singularName: (t) => t("Lifting System"),
  name: (t) => t("Lifting Systems"),
  syncCustomerFilter: true,
};

export default conf;
