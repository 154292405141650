import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { usersToMemberEdit } from "../helpers/utils";

const conf: EntityConfig = {
  ...entityFactory("technician"),
  endpoint: "technicians",
  roleView: [ROLE.APP_SERVICE_USER, ROLE.APP_SERVICE_USER],
  roleAdmin: [ROLE.APP_SERVICE_USER],
  singularName: (t) => t("Technician"),
  name: (t) => t("Technicians"),
  infiniteNormalizer: (value) => ({
    rowCount: value.rowCount,
    first: value.first,
    results: usersToMemberEdit(value.results ?? []),
  }),
  sendCulture: true,
  dependencies: ["contract"],
  syncCustomerFilter: true,
};

export default conf;
