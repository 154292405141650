import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { rangeFilterUTC } from "../helpers/utils";
import { ServiceCallListDto } from "../eGate-API";
import { fullFormatUtcToLocal } from "../helpers/timeUtils";
import ServiceCallTypeTranslations from "./ServiceCallTypeTranslations";
import { TFunction } from "i18next";

const conf: EntityConfig = {
  ...entityFactory("service-calls"),
  endpoint: "service-calls",
  roleView: [ROLE.SC_VIEW, ROLE.SC_ADMIN],
  roleAdmin: [ROLE.SC_ADMIN],
  baseRoute: "service-calls",
  singularName: (t) => t("Service call"),
  name: (t) => t("Service calls"),
  syncFilterWithURL: true,
  sendCulture: true,
  initialFilter: {
    sortBy: "receivedOn",
    sortOrder: "descend",
  },
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
  infiniteNormalizer: (value, t) => ({
    rowCount: value.rowCount,
    first: value.first,
    results: value.results.map((i) => ({ name: joinServiceCallName(i, t), id: i.id })),
  }),
};

export default conf;

export function joinServiceCallName(serviceCall: ServiceCallListDto, t: TFunction): string {
  const hwUnit = serviceCall?.hardwareUnit?.name ?? "";
  const date = fullFormatUtcToLocal(serviceCall?.receivedOn);
  return `${hwUnit} ${ServiceCallTypeTranslations(t).translation(serviceCall?.type)} - ${date}`;
}
