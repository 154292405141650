import React, { ReactNode } from "react";
import { BoxTitle } from "./boxTitle.style";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { emzPrimaryColor } from "../../helpers/utils";
import classNames from "classnames";
import { Button } from "antd";
import FaIcon from "../FaIcon";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-light-svg-icons";

const BoxTitleWrapper = styled.div`
  width: 100%;

  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: space-evenly;
  color: ${emzPrimaryColor};

  cursor: pointer;

  &.boxy {
    border-radius: 0;
  }
  .box-title-content {
    padding-top: var(--gap-medium);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box-icon {
    margin-left: 20px;
    color: ${emzPrimaryColor};
  }
  svg {
    color: var(--primary) !important;
  }
  .expand-wrapper {
    margin-right: var(--gap);
  }
  .expandable {
    padding-bottom: var(--gap);
  }
`;

export default (props: {
  title?: React.ReactNode;
  subtitle?: string;
  icon?: IconProp;
  inner?: boolean;
  plain?: boolean;
  middle?: boolean;
  underline?: boolean;
  extra?: ReactNode;
  toggleOpen?: () => void;
  open?: boolean;
  expandable?: boolean;
}) => (
  <BoxTitleWrapper className={props.inner ? "boxy" : ""} onClick={() => props?.toggleOpen?.()}>
    {!props.plain && props.middle && <div className={"border-div"} />}

    <div className={classNames("box-title-content", { expandable: props.expandable })}>
      <div className={"flex flex-row"}>
        {props.icon && <FontAwesomeIcon className={"box-icon"} icon={props.icon} size={"lg"} />}

        {props.title ? <BoxTitle className="isoBoxTitle"> {props.title} </BoxTitle> : ""}
      </div>

      {props.expandable && (
        <div className={"expand-wrapper"}>
          {props.open && <Button icon={<FaIcon icon={faChevronUp} size={"lg"} />} type={"link"}></Button>}
          {!props.open && <Button icon={<FaIcon icon={faChevronDown} size={"lg"} />} type={"link"}></Button>}
        </div>
      )}
    </div>
  </BoxTitleWrapper>
);
