import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("service-task-solutions"),
  endpoint: "service-task-solutions",
  roleView: [ROLE.SC_TASK_CATEGORY_VIEW, ROLE.SC_TASK_CATEGORY_ADMIN],
  roleAdmin: [ROLE.SC_TASK_CATEGORY_ADMIN],
  singularName: (t) => t("Service task solution"),
  name: (t) => t("Service task solutions"),
  syncCustomerFilter: true,
  dependencies: ["service-task"],
  sendCulture: true,
};
export default conf;
