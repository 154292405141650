import { useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import useApp from "./useApp";

/**
 * Shortcut navigation hook
 */
export default function () {
  const { appData } = useApp();
  const navigate = useNavigate();

  const isModalOpened = appData?.isModalOpened;
  useHotkeys("alt + 1", () => {
    if (isModalOpened) return;
    navigate("/app/user");
  });

  useHotkeys(
    "alt + 2",
    () => {
      if (isModalOpened) return;

      navigate("/app/container");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 3",
    () => {
      if (isModalOpened) return;

      navigate("/app/customer");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 4",
    () => {
      if (isModalOpened) return;

      navigate("/app/contract");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 5",
    () => {
      if (isModalOpened) return;

      navigate("/app/nfc-card");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 6",
    () => {
      if (isModalOpened) return;

      navigate("/app/nfc-card-blacklist");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 7",
    () => {
      if (isModalOpened) return;

      navigate("/app/permission-group");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 8",
    () => {
      if (isModalOpened) return;

      navigate("/app/app-message");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 9",
    () => {
      navigate("/app/profile");
    },
    [isModalOpened]
  );

  useHotkeys(
    "alt + 0",
    () => {
      navigate("/app/region-code");
    },
    [isModalOpened]
  );
}
