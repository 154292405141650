import { createGlobalStyle } from "styled-components";
import { emzPrimaryColor } from "../../helpers/utils";

export const AntOverride = createGlobalStyle`
    

  .ant-spin-nested-loading {
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }
  .ant-picker-middle {
    height: 30px !important;
  }
  
  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
    border-bottom: none !important;
  }

  .ant-tabs-tab {
    padding: 0 !important;
  }

  .ant-tabs-tab-btn {
    margin-bottom: 8px;
  }
  

  .ant-btn-background-ghost:not(.ant-btn-default):not(.ant-btn-dangerous) {
    color: var(--light-gray-secondary-text) !important;

    &:hover {

      color: rgba(0, 175, 91, 0.5) !important;
    }
  }

  .ant-btn {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 9px;
    }
  }


  .ant-tabs-nav-list {
    margin-right: 1rem;
  }

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: initial !important;
  }

  .ant-btn {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn .anticon {
    margin-right: 0 !important
  }

  .ant-pagination-item, .ant-pagination-item-link {
    border-radius: 50px !important;

  }

  .ant-menu-submenu-popup {
    svg {
      color: lightgray;
    }
  }

  .ant-picker {
    border-radius: 10px !important;
  }


  .ant-btn-primary {
    &.ant-btn-background-ghost {
      span, svg, .anticon {
        color: ${emzPrimaryColor} !important;
      }

    }

    //svg, span {
    //  color: white !important;
    //}

  }

  .ant-menu-horizontal {
    border: none;

    &:hover {
      border: none;
    }
  }

  .ant-menu-submenu:hover::after, .ant-menu-submenu-active::after, .ant-menu-submenu-open::after, .ant-menu-submenu-selected::after {
    border-bottom: 0 !important;
  }

  .ant-menu-submenu::after {
    border-bottom: 0 !important;
  }

  .ant-menu-item:hover::after, .ant-menu-item::after, .ant-menu-item::after, .ant-menu-item::after {
    border-bottom: #41d013 !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    border: none;
  }

  .ant-menu-item-active:hover {
    border: none !important;
  }

  .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    color: #000;
    transform: translateY(-50%);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .ant-tabs-tab-btn {
    font-weight: 500 !important;
    color: var(--black-primary-text) !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500 !important;
    color: ${emzPrimaryColor} !important;
  }

  .ant-list {
    font-size: 15px;
    font-weight: 500;
  }


  .ant-drawer-body {
    padding: 0 !important;
  }

  .ant-drawer-content-wrapper {
    border-radius: var(--corner-medium);
    overflow: hidden;
  }
  
  .ant-form-item-label {
    overflow: visible;
  }

  .ant-btn-link {
    padding: 0 !important;
  }

  .ant-popconfirm {
    width: 250px;
    height: 130px;

    .anticon-exclamation-circle {
      width: 100% !important;

      svg {
        transform: scale(1.8);

      }
    }

    .ant-popconfirm-message-title {
      padding-left: 0;
    }
    .ant-popconfirm-message-icon {
      display: none;
    }

    .ant-popconfirm-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .ant-btn {
        width: 110px;
        height: 33px;
      }
    }
  }

  .expandable-table {
    .ant-table-row-expand-icon-cell {
      min-width: 50px !important;
      padding: 0 !important;
      padding-left: 10px !important;
    }

    .after-expandable {
      padding-left: 0;
    }
  }
  
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .tableCursor {
    cursor: pointer;
  }

  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td {
    background: #ececec !important;
  }

  .ant-input-search > .ant-input-prefix:hover {
    cursor: pointer;
    color: var(--primary);
  }
  

  .ant-upload.ant-upload-drag.custom-file-upload {
      width: 100%;
      height:250px;
      background: #DAFFED;
      border: 2px dashed #03AF5C;
  }

  .ant-menu.ant-menu-root:focus-visible {
    box-shadow: none !important;
  }
  
  .side-menu {
    border: none !important;
  }
  
  .ant-modal-confirm-body > span {
    display: none;
  }

  .ant-modal-body .anticon-info-circle {
    display: none !important;
  }
  .ant-modal-confirm-content {
    margin-left: 10px !important;
  }

  .ant-modal-confirm .ant-modal-confirm-body {
    display: block !important;
  }
  
  .ant-btn {
    font-weight: 500 !important;
  }
  .ant-btn-loading-icon {
    margin-right: 6px !important;
  }
  
  .ant-dropdown-menu-item {
    gap: 8px;
  }
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      background-color: var(--primary);
    }
  }
  
  .ant-list-empty-text {
    padding: 0 !important;
  }
  .ant-empty {
    margin: 0 !important;
    .ant-empty-image {
      height: 25px;
      margin-bottom: 5px !important;
    }
    .ant-list-empty-text {
      padding: 0 !important;
    }
  }


  .ant-input-number-input:disabled {
     color: rgba(0, 0, 0, 0.25) !important;
   }
  
  .ant-modal {
      top: 10px !important;
  }
  
  .ant-table-thead {
      .ant-table-cell {
          white-space: nowrap;
          text-overflow: ellipsis;
      }
  }
  .ant-table-placeholder {
      min-height: 300px !important;
  }
  .ant-drawer-header-title {
      flex-direction: row-reverse;
  }

  .ant-collapse {
  
      background: white !important;
  }
  
  
`;
