import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import i18nEng from "./translations/en/translation.json";
import i18nDe from "./translations/de/translation.json";
import i18nIt from "./translations/it/translation.json";
import i18nPt from "./translations/pt/translation.json";
import i18nFr from "./translations/fr/translation.json";
import i18nDa from "./translations/da/translation.json";
import i18nHr from "./translations/hr/translation.json";
import i18nCs from "./translations/cs/translation.json";
import i18nPl from "./translations/pl/translation.json";

import i18nRolesEng from "./translations/en/roles.json";
import i18nRolesDe from "./translations/de/roles.json";
import i18nRolesIt from "./translations/it/roles.json";
import i18nRolesPt from "./translations/pt/roles.json";
import i18nRolesFr from "./translations/fr/roles.json";
import i18nRolesDa from "./translations/da/roles.json";
import i18nRolesHr from "./translations/hr/roles.json";
import i18nRolesCs from "./translations/cs/roles.json";
import i18nRolesPl from "./translations/pl/roles.json";

// the translations

export const resources = {
  en: { translation: i18nEng, roles: i18nRolesEng },
  de: { translation: i18nDe, roles: i18nRolesDe },
  it: { translation: i18nIt, roles: i18nRolesIt },
  pt: { translation: i18nPt, roles: i18nRolesPt },
  fr: { translation: i18nFr, roles: i18nRolesFr },
  da: { translation: i18nDa, roles: i18nRolesDa },
  hr: { translation: i18nHr, roles: i18nRolesHr },
  cs: { translation: i18nCs, roles: i18nRolesCs },
  pl: { translation: i18nPl, roles: i18nRolesPl },
};

export function resolveLanguage(lang: keyof typeof resources) {
  if (resources?.[lang]) {
    return lang;
  }
  return "en";
}
void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: "en",
      keySeparator: false, // we do not use keys in form messages.welcome
      returnEmptyString: false,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    (error, t) => {
      // Only for the extractor
      t("en");
      t("de");
      t("it");
      t("pt");
      t("fr");
      t("da");
      t("hr");
      t("cs");
      t("pl");
      t("We are sorry but eGate application does not support Internet Explorer.");
      t(
        "Internet Explorer is no longer supported and maintained by Microsoft, does not adhere to modern web standards and may contain serious security vulnerabilities."
      );
      t("Please consider using a more current and safer browser.");
    }
  );

export default i18n;
