import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("app-message"),
  endpoint: "app-messages",
  roleView: [ROLE.APP_MESSAGE_ADMIN, ROLE.APP_MESSAGE_VIEW],
  roleAdmin: [ROLE.APP_MESSAGE_ADMIN],
  singularName: (t) => t("App message"),
  name: (t) => t("App messages"),
  syncCustomerFilter: true,
};

export default conf;
