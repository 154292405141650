import React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Box from "./utility/Box";

export default function () {
  const { t } = useTranslation();

  return (
    <Box>
      <Result
        extra={
          <Link to={"/app/dashboard"}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
        status="404"
        subTitle={t("Sorry, the page you visited does not exist.")}
        title="404"
      />
    </Box>
  );
}
