import { fetchEGate } from "../eGateApi";

/**
 * Gets whether field value for given entity is already used
 *
 * @param entity - Name of entity to test, in plural (e.g. users)
 * @param field - Name of field to test (e.g. "email")
 * @param value - Value to test
 * @param message - Message if exists
 * @param scope - Scope definition. If not provided, global scope is used
 */
export async function isValueUsed<TEntity, TValue>(
  entity: string,
  field: keyof TEntity,
  value: TValue,
  message: string,
  scope?: Record<keyof TEntity, unknown>
): Promise<void> {
  if (!value) return Promise.resolve();
  return fetchEGate("POST", `${entity}/value-used`, {
    field,
    value,
    scope,
  }).then(
    (response) => {
      if (response.status === 200) {
        return response.json().then((bool) => {
          if (bool) {
            return Promise.reject(message);
          }
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    },
    (reason) => {
      console.log(reason);
    }
  );
}
