import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("container-lock-type-aliases"),
  endpoint: "container-lock-type-aliases",
  roleView: [ROLE.LOCK_TYPE_ALIAS_VIEW, ROLE.LOCK_TYPE_ALIAS_FORM],
  roleAdmin: [ROLE.LOCK_TYPE_ALIAS_FORM],
  singularName: (t) => t("Lock Type Alias"),
  name: (t) => t("Lock Type Aliases"),
  syncCustomerFilter: true,
};

export default conf;
