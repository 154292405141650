// import { css } from 'styled-components';

// Transition
/**
 * Returns css transition code with timing form parameter
 *
 * @param timing {number} Timing
 * @param target
 * @returns {string}
 */
export function transition(timing = 0.3, target = "all") {
  return `
      -webkit-transition: ${target} ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: ${target} ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: ${target} ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: ${target} ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: ${target} ${timing}s cubic-bezier(0.215, 0.61, 0.355, 1);
  `;
}

// Border Radius
/**
 * @param radius
 */
export function borderRadius(radius = 0) {
  return `
      -webkit-border-radius: ${radius};
      -moz-border-radius: ${radius};
      -o-border-radius: ${radius};
      border-radius: ${radius};
  `;
}

// Box Shadow
/**
 * @param shadow
 */
export function boxShadow(shadow = "none") {
  return `
      -webkit-box-shadow: ${shadow};
      -moz-box-shadow: ${shadow};
      box-shadow: ${shadow};
  `;
}
