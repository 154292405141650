import React from "react";

import styled from "styled-components";
import logo from "../image/main-logo.svg";

const Style = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 505;
  width: 100vw;
  height: 80px;

  .simple-bar-content {
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    h3 {
      margin: 0;
      margin-left: 2rem;
      font-family: Manrope, serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;

export default function () {
  return (
    <Style className={"simple-top-bar"}>
      <div className={"simple-bar-content"}>
        <img alt="eDigi logo" height="100%" src={logo} width="128px" />
      </div>
    </Style>
  );
}
