import { ROLE } from "../Auth/useRole";
import { EntityConfig, IDType, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("customer-features"),
  endpoint: "customers/features",
  getDetailEndpoint: (id: IDType) => `customers/${id}/features`,
  roleView: [ROLE.FEATURE_VIEW, ROLE.FEATURE_ADMIN],
  roleAdmin: [ROLE.FEATURE_ADMIN],
  baseRoute: "customer-features",
  singularName: (t) => t("Customer features"),
  name: (t) => t("Customer features"),
  noIdInCall: true,
  noIdInUrl: true,
  dependencies: ["customer"],
  syncCustomerFilter: true,
};

export default conf;
