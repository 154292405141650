import * as Sentry from "@sentry/browser";
import * as qs from "qs";
import { Dictionary } from "../components/FormFieldValidators";
import { notification } from "antd";

/**
 * Supported HTTP methods
 */
export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

// Take base URL from document URL
const host = window.location.origin ?? `${window.location.protocol}//${window.location.host}`;
const BASE_URL = `${host}/`;

const API_VERSION = "v1";
export const XSRF_TOKEN_NAME = "X-XSRF-TOKEN";

/**
 * Constructs URL for endpoint
 *
 * @param endpoint - Endpoint name to use in URL
 * @param privateUrl - Optional, indicatesIs this is private API endpoint
 * @param version - Optional, allows to override the API version to call
 *
 * @returns String with endpoint URL
 */
function getUrl(endpoint: string, privateUrl?: boolean, version?: string) {
  function getVersion() {
    return version ? version : API_VERSION;
  }
  if (privateUrl) {
    return BASE_URL + "api/private/" + getVersion() + "/" + endpoint;
  }
  return BASE_URL + "api/" + getVersion() + "/" + endpoint;
}

/**
 * Constructs URL with query part
 *
 * @param url - Base URL
 * @param query - Query data
 *
 * @returns Base URL with appended query parameters
 */
function getUrlWithQuery(url: string, query: any) {
  if (query) {
    return url + "?" + qs.stringify(query, { arrayFormat: "repeat" });
  }
  return url;
}

/**
 * Creates URL to EGate API
 *
 * @param endpoint - Endpoint to call
 * @param query    - Optional HTTP Query parameters
 * @param privateUrl
 * @param version
 */
export function linkEGate(endpoint: string, query?: any, privateUrl?: boolean, version?: string): string {
  const url = getUrl(endpoint, privateUrl, version);
  return getUrlWithQuery(url, query);
}

/**
 * Structure returned from API in case of error
 */
export interface ApiError {
  /** HTTP Status */
  status?: number;
  /** Description of the issue */
  detail?: string;
  /** Errors encountered */
  errors?: Dictionary<string[]>;
  /** Type of the issue */
  type?: string;
  /** Sentry Id */
  sentryId?: string;
}

/**
 * Optional parameters for fetch
 */
interface FetchEGateParams {
  /** Indicate private API endpoint */
  privateUrl?: boolean;
  /** Optional request abort conroller*/
  ac?: AbortController;
  /** Indicates whether response notification shoul be shown*/
  showNotification?: boolean;
  /** API version override */
  version?: string;
}

/**
 * Calls the eGate API
 *
 * @param method   - HTTP method to use
 * @param endpoint - Endpoint to call
 * @param payload  - Optional Payload to use
 * @param query    - Optional HTTP Query parameters
 * @param params   - Optional additional parameters for the call
 *
 * @returns Promise with the response from API
 */
export function fetchEGate(
  method: HttpMethod,
  endpoint: string,
  payload?: any,
  query?: any,
  params?: FetchEGateParams
): Promise<Response> {
  const url = linkEGate(endpoint, query, params?.privateUrl, params?.version);
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };

  const controller = params?.ac;
  const signal = controller?.signal;
  if (method !== "GET") {
    headers[XSRF_TOKEN_NAME] = GetXsrfToken();
  }
  const config: RequestInit = {
    mode: "cors",
    method,
    headers,
    signal,
  };
  if ((payload && method === "POST") || method === "PUT" || method === "DELETE") {
    config.body = JSON.stringify(payload);
  }

  const promise = fetch(url, config);

  return promise.then((e) => {
    if (e.ok) return e;
    //check if the response is JSON
    if (!e.headers.get("content-type")?.includes("json")) {
      return e.text().then((text) => {
        let sentryId = undefined;

        if (e.status === 500) {
          sentryId = Sentry.captureException(new Error(e.statusText), {
            contexts: {
              apiError: {
                status: e.status,
                endpoint: url,
                payload,
                query,
                detail: e.statusText,
                body: text,
              },
            },
          });
        }
        if (params?.showNotification !== false && text) {
          notification.error({ message: text, placement: "top" });
        }

        return Promise.reject({
          status: e.status,
          errors: ["API error"],
          type: "",
          detail: text,
          sentryId,
        });
      });
    }

    if (e.headers.get("content-type")?.includes("json")) {
      return e.json().then((errorJson) => {
        let sentryId = undefined;
        const errorName = errorJson.detail ?? errorJson.title ?? e.statusText;

        if (e.status === 500) {
          sentryId = Sentry.captureException(new Error(errorJson.detail ?? errorJson.title ?? e.statusText), {
            contexts: {
              apiError: {
                status: e.status,
                endpoint: url,
                payload,
                query,
                detail: errorName,
                body: errorJson,
              },
            },
          });
        }
        if (params?.showNotification !== false) {
          if (errorJson?.errors) {
            notification.error({ message: Object.values(errorJson.errors).join(", "), placement: "top" });
          } else if (errorJson.detail) {
            notification.error({ message: errorName, placement: "top" });
          }
        }

        return Promise.reject({
          status: errorJson.status,
          errors: errorJson.errors,
          type: errorJson.type,
          detail: errorName,
          sentryId,
        });
      });
    }
    return Promise.reject({
      status: 500,
      errors: ["unknown error"],
      type: "",
      detail: e.statusText,
    });
  });
}

/**
 * Calls the eGate API
 *
 * @param method   - HTTP method to use
 * @param endpoint - Endpoint to call
 * @param file  - Optional Payload to use
 * @param name - Parameter name - If the name is undefined the file will be put in the body itself
 * @param contentType    - Content type
 */
export async function uploadFile(
  method: HttpMethod,
  endpoint: string,
  file: any,
  name?: string,
  contentType?: any
): Promise<Response> {
  const url = linkEGate(endpoint, undefined);
  const formData = new FormData();
  const headers: Record<string, string> = {};
  let body;
  if (name) {
    formData.append(name, file);
    body = formData;
  } else {
    body = file;

    headers["Content-Type"] = contentType;
  }
  if (method !== "GET") {
    headers[XSRF_TOKEN_NAME] = GetXsrfToken();
  }
  const config: RequestInit = {
    mode: "cors",
    method,
    body,
    headers,
  };

  const response = await fetch(url, config);
  if (response.ok) {
    return response;
  }
  const errorJson = await response.json();

  if (errorJson?.errors) {
    notification.error({ message: Object.values(errorJson.errors).join(", "), placement: "top" });
  } else if (errorJson.detail) {
    notification.error({ message: Object.values(errorJson.detail), placement: "top" });
  }

  return response;
}

/**
 * Calls the eGate API
 *
 * @param method   - HTTP method to use
 * @param endpoint - Endpoint to call
 * @param file  - Optional Payload to use
 * @param name - Parameter name - If the name is undefined the file will be put in the body itself
 * @param contentType    - Content type
 */
export function uploadFileDirect(
  method: HttpMethod,
  endpoint: string,
  file: any,
  name: string,
  contentType?: any
): Promise<Response> {
  const url = linkEGate(endpoint, undefined);
  const headers: Record<string, string> = {};
  headers["Content-Type"] = contentType;

  headers[XSRF_TOKEN_NAME] = GetXsrfToken();
  headers["File-Name"] = name;

  const config: RequestInit = {
    mode: "cors",
    method,
    body: file,
    headers,
  };
  return fetch(url, config);
}

/**
 * Gets Cookie
 *
 * @param cookieName Name of the cookie
 */
export function getCookie(cookieName: string): string {
  const name = cookieName + "=";
  const cookiesString = decodeURIComponent(document.cookie);
  const cookies = cookiesString.split(";");

  for (let cookie of cookies) {
    // Remove trailing whitespace
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    // Check for wanted cookie
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

/**
 * Gets XSRF-Token from cookie
 */
export function GetXsrfToken(): string {
  return getCookie("eGateDigi-JWT");
}
