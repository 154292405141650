import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";

const conf: EntityConfig = {
  ...entityFactory("bi-dashboards"),
  endpoint: "superset-dashboards",
  roleView: [ROLE.BI_DASHBOARD_RUN],
  roleAdmin: [ROLE.BI_DASHBOARD_RUN],
  baseRoute: "bi-dashboards",
  singularName: (t) => t("BI Dashboard"),
  name: (t) => t("BI Dashboard"),
  disableCache: true,
};

export default conf;
