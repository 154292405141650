import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { rangeFilterUTC } from "../helpers/utils";
import { ServiceAppEventHeaderDto } from "../eGate-API";
import { fullFormatUtcToLocal } from "../helpers/timeUtils";

const conf: EntityConfig = {
  ...entityFactory("service-app-events"),
  endpoint: "service-app-events",
  roleView: [ROLE.SC_APP_EVENT_VIEW, ROLE.SC_APP_EVENT_ADMIN],
  roleAdmin: [ROLE.SC_APP_EVENT_ADMIN],
  baseRoute: "service-app-events",
  singularName: (t) => t("Service app event"),
  name: (t) => t("Service app events"),
  syncFilterWithURL: true,
  sendCulture: true,
  initialFilter: {
    sortBy: "receivedOn",
    sortOrder: "descend",
  },
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
  infiniteNormalizer: (value) => ({
    rowCount: value.rowCount,
    first: value.first,
    results: value.results.map((i) => ({ name: joinServiceCallName(i), id: i.id })),
  }),
};

export default conf;

export function joinServiceCallName(serviceCall: ServiceAppEventHeaderDto): string {
  const hwUnit = serviceCall?.hardwareUnit?.name ?? "";
  const date = fullFormatUtcToLocal(serviceCall?.receivedOn);
  return `${hwUnit}  - ${date}`;
}
