import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("fill-level-sensor-type"),
  endpoint: "fill-level-sensor-types",
  roleView: [ROLE.FILL_SENSOR_VIEW, ROLE.FILL_SENSOR_ADMIN],
  roleAdmin: [ROLE.FILL_SENSOR_ADMIN],

  singularName: (t) => t("Fill Level Sensor type"),
  name: (t) => t("Fill Level Sensor types"),
  syncCustomerFilter: true,
};

export default conf;
