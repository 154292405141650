import { Button, Form, Modal } from "antd";
import PasswordField, {
  passwordConfirmationRule,
  getPawnedPasswordValidator,
} from "../components/FormFields/PasswordField";
import { required } from "../components/FormFieldValidators";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PasswordResetRequestCreateDto, PasswordResetValidationResultDto } from "../eGate-API";
import { useForm, useWatch } from "antd/es/form/Form";
import classNames from "classnames";
import { toJson } from "../helpers/apiHelpers";
import { fetchEGate } from "../helpers/eGateApi";
import { useNavigate } from "react-router-dom";
import CheckmarkText from "../components/CheckmarkText";

interface FinishFormProps {
  onSubmit: (values: PasswordResetRequestCreateDto) => void;
  disabled?: boolean;
  token?: string;
}

export default function PasswordResetFinishForm(props: FinishFormProps) {
  const { t } = useTranslation();
  const [form] = useForm();

  const navigate = useNavigate();

  const [validationResult, setValidationResult] = useState<PasswordResetValidationResultDto>();

  async function asyncValidateNewPassword(value: string) {
    if (value.length < 8) {
      return Promise.resolve();
    }
    try {
      const result = await toJson<PasswordResetValidationResultDto>(
        fetchEGate("PUT", "users/password-reset/validate", { token: props.token, newPassword: value }, undefined, {
          showNotification: false,
        })
      );
      setValidationResult(result);
      if (result.status === "weak_password") {
        return Promise.reject(result.errors["NewPassword"].join(", "));
      }

      if (result.status === "expired") {
        Modal.error({
          content: t("Password reset is no longer possible. Try again."),
          onOk: () => {
            navigate("/reset-password", { replace: true });
          },
          onCancel: () => {
            navigate("/reset-password", { replace: true });
          },
        });
        return Promise.reject();
      }

      return Promise.resolve();
    } catch (result: any) {
      return Promise.reject(result.errors["NewPassword"].join(", "));
    }
  }

  const newPassword: string = useWatch("newPassword", form);
  const confirmPassword: string = useWatch("confirmPassword", form);

  const errorCodes = validationResult?.errorCodes?.["NewPassword"];
  const isLong = newPassword?.length >= 8;

  return (
    <Form form={form} layout="vertical" onFinish={props.onSubmit}>
      <h3 className={"reset-heading"}>{t("Set new password")}</h3>

      <p>{t("In order to protect your account, make sure your password")}:</p>

      <ul className={"no-bullets"}>
        <li>
          <CheckmarkText checked={isLong} text={t("Minimum of {{0}} characters", { 0: 8 })}></CheckmarkText>
        </li>
        <li>
          <CheckmarkText
            checked={!errorCodes?.includes("PasswordMustNotContainUserData")}
            disabled={!isLong}
            text={t("Must not contain the user name or email.")}
          />
        </li>
      </ul>

      <PasswordField
        autocomplete="off"
        dependencies={["confirmPassword"]}
        label={t("Password")}
        name={"newPassword"}
        nativeInput
        placeholder={t("Password")}
        required={true}
        rules={[
          required(t, true, t("Password is required")),
          {
            min: 8,
            message: t("Password must be at least {{minPasswordLength}} characters long", {
              minPasswordLength: 8,
            }) as string,
          },
          getPawnedPasswordValidator(t),
          { validator: (_, value) => asyncValidateNewPassword(value) },
        ]}
      />

      <PasswordField
        autocomplete="off"
        dependencies={["newPassword"]}
        label={t("Confirm Password")}
        name={"confirmPassword"}
        nativeInput
        placeholder={t("Confirm password")}
        required={true}
        rules={[required(t, true, t("Password is required")), passwordConfirmationRule(t, form, true)]}
      />

      <Form.Item noStyle shouldUpdate>
        {() => {
          const hasError = form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
          const isPristine = !form.isFieldsTouched(true);
          const isDisabled = !confirmPassword || !newPassword || hasError;
          return (
            <Button
              className={classNames("login-button", {
                "disabled": isDisabled,
              })}
              disabled={props.disabled || isDisabled || isPristine}
              htmlType="submit"
              id="btnResetPassword"
              loading={props.disabled}
              type="primary"
            >
              {t("Set new password")}
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
}
