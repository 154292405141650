import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";
import { PartialListDto, SupersetDashboardListDto } from "../eGate-API";

const conf: EntityConfig = {
  ...entityFactory("bi-dashboards-management"),
  endpoint: "superset-dashboards",
  roleView: [ROLE.BI_DASHBOARD_VIEW, ROLE.BI_DASHBOARD_ADMIN],
  roleAdmin: [ROLE.BI_DASHBOARD_ADMIN],
  baseRoute: "bi-dashboards-management",
  singularName: (t) => t("BI Dashboard Management"),
  name: (t) => t("BI Dashboard Management"),
  disableCache: true,
  infiniteNormalizer: (value: PartialListDto<SupersetDashboardListDto>) => {
    const results = value.results.map((i) => ({
      id: i.id,
      name: i.name + " - " + i.customerName,
    }));

    return {
      ...value,
      results,
    };
  },
  onlyWithFeature: "BI_TOOLS",
};

export default conf;
