import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("time-preset"),
  endpoint: "interval-configuration-groups",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  singularName: (t) => t("Time preset"),
  name: (t) => t("Time presets"),
  syncCustomerFilter: true,
  dependencies: ["container"],
};

export default conf;
