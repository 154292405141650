import React, { MouseEventHandler } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { BaseElementProps } from "../types/BaseElementProps";
import styled from "styled-components";
import classNames from "classnames";

export interface Props {
  icon: IconProp;
  size?: SizeProp;
  onClick?: MouseEventHandler<any> | undefined;
  color?: string;
  hoverPrimaryColor?: boolean;
  id?: string;
  title?: string;
  role?: string;
}

export const FakeAntdIcon = styled.span`
  &.anticon {
    display: inline-block;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .fa-fls {
    transform: scale(0.8);
  }
`;

export default function (props: Props & BaseElementProps) {
  return (
    <FakeAntdIcon
      className={classNames(props.className, "anticon", { "hover-main-color": props.hoverPrimaryColor })}
      id={props.id}
      role={props.role}
      style={{ color: props.color }}
      title={props.title}
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={props.icon} size={props.size} style={props.style} />
    </FakeAntdIcon>
  );
}
