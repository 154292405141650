import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("customer"),
  endpoint: "customers",
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN],
  roleAdmin: [ROLE.CUSTOMER_ADMIN],
  tabCountView: 7,
  singularName: (t) => t("Customer"),
  name: (t) => t("Customers"),
};

export default conf;
