import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button, Result, Spin } from "antd";
import { PasswordResetRequestCreateDto, PasswordResetResultDto } from "../eGate-API";

import LoginStyler from "./Login.style";
import { useEffectOnce, useToggle } from "react-use";
import { toJson } from "../helpers/apiHelpers";
import { fetchEGate } from "../helpers/eGateApi";

import PasswordResetFinishForm from "./PasswordResetFinishForm";
import SimpleTopBar from "./SimpleTopBar";
import LoginFooter from "./LoginFooter";
import styled from "styled-components";

const Style = styled.div`
  .reset-heading {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0;
    text-align: center;
    padding-bottom: 2rem;
  }
  ul {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
  }
  a {
    margin-top: 2rem;
    text-align: center;
  }
`;

function PasswordResetFinish() {
  const { t } = useTranslation();
  const [isFetching, toggleisFetching] = useToggle(false);
  const [isFinalFetching, toggleFinalFetching] = useToggle(false);
  const [success, setSuccesses] = useToggle(false);
  const [finishApiError, setFinishError] = useState();
  const [tokenApiError, setTokenApiError] = useState();
  const [tokenResult, setTokenResult] = useState<PasswordResetResultDto>();
  const [finishResult, setFinishResult] = useState<PasswordResetResultDto>();
  const { token } = useParams<{ token: string }>();

  useEffectOnce(() => {
    if (!token) return;
    (async () => {
      try {
        toggleisFetching();
        const result: PasswordResetResultDto = await toJson<PasswordResetResultDto>(
          fetchEGate("GET", `users/password-reset/${token}`)
        );
        setTokenResult(result);
      } catch (e: any) {
        setTokenApiError(e.detail);
        setTokenResult(undefined);
      }
      toggleisFetching();
    })();
  });

  async function finishPasswordReset(payload: any) {
    toggleFinalFetching();
    try {
      const verificationResult: PasswordResetResultDto = await toJson<PasswordResetResultDto>(
        fetchEGate("PUT", `users/password-reset`, payload)
      );
      setFinishResult(verificationResult);
      setSuccesses(true);
      setTokenResult(undefined);

      if (verificationResult?.status === "weak_password") {
        setSuccesses(false);
      }
    } catch (e: any) {
      setSuccesses(false);

      setFinishError(e.detail);
    }
    toggleFinalFetching();
  }

  /**
   * Submit form callback
   * @param {} values
   */
  async function handleSubmit(values: any) {
    if (isFetching) return; // Prevent multi-submit
    const request = {
      token,
      newPassword: values.newPassword,
    };
    await finishPasswordReset(request);
  }

  const done = finishResult?.status === "done" || tokenResult?.status === "done";
  const pending = finishResult?.status === "pending" || tokenResult?.status === "pending";
  const expired = finishResult?.status === "expired" || tokenResult?.status === "expired";
  const weakPassword = finishResult?.status === "weak_password" || tokenResult?.status === "weak_password";
  const invalidToken = tokenApiError || expired || done;
  const finishError = finishApiError || finishResult?.errors || weakPassword;

  return (
    <LoginStyler className="isoSignInPage">
      <SimpleTopBar />

      <Style className="login-content">
        <PasswordResetResult
          done={done}
          expired={expired}
          finishError={finishError as boolean}
          handleSubmit={handleSubmit}
          invalidToken={invalidToken}
          isFetching={isFetching}
          isFinalFetching={isFinalFetching}
          pending={pending}
          success={success}
          token={token}
        />
        <Link id={"back-to-login"} to="/signin">
          {t("Back to login")}
        </Link>
      </Style>
      <LoginFooter />
    </LoginStyler>
  );
}

interface Pr {
  pending?: boolean;
  done?: boolean;
  isFetching?: boolean;
  isFinalFetching?: boolean;
  success: boolean;
  invalidToken?: boolean;
  expired?: boolean;
  token?: string;
  finishError?: boolean;
  handleSubmit: (values: PasswordResetRequestCreateDto) => void;
  finishResult?: PasswordResetResultDto;
}

/**
 * Show status of request if not pending
 */
function InvalidToken(props: { expired?: boolean }) {
  const { t } = useTranslation();

  return <Result status="error" title={props.expired ? t("Request expired") : t("Already used")} />;
}

function PasswordResetResult(props: Pr) {
  if (props.isFetching) {
    return <Spin />;
  }
  if (props.pending && !props.done) {
    return (
      <PasswordResetFinishForm
        disabled={props.isFetching || props.isFinalFetching}
        token={props.token}
        onSubmit={props.handleSubmit}
      />
    );
  } else if (props.finishError || props.success) {
    return <FinishResult finishResult={props.finishResult} success={props.success} />;
  } else if (props.invalidToken) {
    return <InvalidToken expired={props.expired} />;
  }
  return <div />;
}

interface FinishProps {
  success?: boolean;
  finishResult?: PasswordResetResultDto;
}

/**
 * Show status of request after the password is set
 */
function FinishResult(props: FinishProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const finishResult = props.finishResult;

  const resultStatus = props.success ? "success" : "error";
  const title = props.success ? t("Your password was set") : t("Failed to set your password");
  const subtitle = finishResult
    ? `${finishResult?.description ?? ""} ${finishResult?.errors?.NewPassword?.join(" ") ?? ""}`
    : null;

  return (
    <>
      <Result
        extra={[
          <Button
            key="ok"
            className={"login-button"}
            type="primary"
            onClick={() => {
              if (props.success) {
                navigate("/signin");
              } else {
                history.go(0);
              }
            }}
          >
            {t("Ok")}
          </Button>,
        ]}
        status={resultStatus}
        subTitle={subtitle}
        title={title}
      />
    </>
  );
}

export default PasswordResetFinish;
