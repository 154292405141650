import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("fill-level-sensors-containers"),
  endpoint: "fill-level-sensors/containers",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  singularName: (t) => t("Container"),
  name: (t) => t("Fill Containers"),
  initialFilter: {
    status: "all",
  },
  syncCustomerFilter: true,
  dependencies: ["region-code"],
  syncFilterWithURL: true,
};

export default conf;
