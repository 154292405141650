import { EntityConfig, entityFactory } from "../../hooks/useEntity";
import dayjs from "dayjs";
import { ROLE } from "../../Auth/useRole";
import { ContainerTransactionFilter } from "../../eGate-API";
import { formatToUtc } from "../../helpers/timeUtils";

export const conf: EntityConfig = {
  ...entityFactory("transaction-charts"),
  endpoint: "reports/transactions-daily-count",
  roleView: [ROLE.TRANSACTION_VIEW],
  roleAdmin: [ROLE.TRANSACTION_VIEW],
  singularName: (t) => t("Transaction chart"),
  name: (t) => t("Transaction charts"),
  initialFilter: {
    from: dayjs().startOf("week").format(),
    to: dayjs().endOf("week").format(),
  },
  syncCustomerFilter: true,
  syncFilterWithURL: true,
  filterFormatter: (filter: ContainerTransactionFilter) => {
    return {
      ...filter,
      to: formatToUtc(filter?.to),
      from: formatToUtc(filter?.from),
    };
  },
};

export default conf;
