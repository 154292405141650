import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("container-access-groups"),
  endpoint: "container-access-groups",
  roleView: [ROLE.LARGE_LIST_ADMIN, ROLE.LARGE_LIST_VIEW],
  roleAdmin: [ROLE.LARGE_LIST_ADMIN],
  dependencies: ["container"],
  singularName: (t) => t("Container access groups"),
  name: (t) => t("Container access group"),
  syncCustomerFilter: true,
};

export default conf;
