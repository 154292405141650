import { Form, Input } from "antd";
import { Rule, FormInstance } from "antd/es/form";
import { TFunction } from "i18next";
import React from "react";
import sha1 from "sha1";
import { passwordPawnedCount } from "../../helpers/api/UserApi";
import { FormFieldProps } from "./FormFieldProps";
import FieldDescription from "./FieldDescription";
import { UserDto } from "../../eGate-API";
import PasswordInput from "../../Auth/PasswordInput";
import styled from "styled-components";

const ErrorsStyle = styled.div`
  color: var(--red1) !important;
  min-height: 3.2rem;
  white-space: pre-line;
  padding-bottom: 4px;
`;

/**
 * Password field
 * @param name
 * @param props
 */
export const PasswordField = (props: FormFieldProps) => {
  if (!props.nativeInput) {
    return (
      <Form.Item
        extra={props.description && <FieldDescription description={props.description} label={props.label} />}
        label={props.label}
        name={props.name}
        rules={props.rules}
      >
        <Input.Password autoComplete={props.autocomplete} disabled={props.disabled} placeholder={props.placeholder} />
      </Form.Item>
    );
  }

  return (
    <Form.Item noStyle shouldUpdate>
      {(fi) => {
        const fieldErrors = fi.getFieldsError([props.name as string]);
        const errors = fieldErrors[0].errors;

        return (
          <>
            <Form.Item
              extra={props.description && <FieldDescription description={props.description} label={props.label} />}
              id={undefined}
              label={props.label}
              name={props.name}
              noStyle={props.nativeInput}
              rules={props.rules}
            >
              <PasswordInput autocomplete={props.autocomplete} placeholder={props.placeholder} type="password" />
            </Form.Item>
            <ErrorsStyle className={"error-wrapper"}>
              {errors.length > 0 && <div>{errors?.join("\n ")}</div>}
            </ErrorsStyle>
          </>
        );
      }}
    </Form.Item>
  );
};

export default PasswordField;

const minPasswordLength = 8;

// Gets whether password matches required length
const isRequiredPasswordLength = (value: string) => value && value.length >= minPasswordLength;

export function getPawnedPasswordValidator(t: TFunction) {
  return {
    validator: (_: any, value: string) => {
      if (isRequiredPasswordLength(value)) {
        // Test whether the password was pawned
        const sha = sha1(value);
        return passwordPawnedCount(sha).then((count: number) => {
          if (count < 1) {
            return Promise.resolve();
          }
          return Promise.reject(t("This password appeared {{count}}-times in known security breaches.", { count }));
        });
      }
      return Promise.resolve();
    },
  };
}

/**
 * Password rules
 * @param t
 * @param user
 * @param required
 */
export function passwordRules(t: TFunction, user: Partial<UserDto>, required = false): Rule[] {
  const list: string[] = [user?.username, user?.email, user?.firstName, user?.lastName, user?.zipCode].filter(
    (i) => i
  ) as string[];

  return [
    {
      required,
      min: minPasswordLength,
      message: t("Password must be at least {{minPasswordLength}} characters long", {
        minPasswordLength,
      }) as string,
    },
    getPawnedPasswordValidator(t),
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.resolve();
        }
        if (/\d/.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject(t("Password must contain at least one digit."));
        }
      },
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.resolve();
        }

        const includesData = list.filter((i) => {
          const re = new RegExp(i, "i");
          return re.exec(value) != null;
        });

        if (includesData.length === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject("Password must not include your username, email, name or zip code.");
        }
      },
    },
  ];
}

/**
 * Validation for password confirmation.
 *
 * @param t
 * @param form
 * @param required - Optional, default True
 */
export function passwordConfirmationRule(t: TFunction, form: FormInstance, required = false): Rule {
  return {
    required,
    validator: (rule, value) => {
      if (!value) {
        return Promise.resolve();
      }
      const password = form.getFieldValue("newPassword");
      if (value !== password) {
        return Promise.reject(t("Mismatch between password and its confirmation"));
      }
      return Promise.resolve();
    },
  };
}
