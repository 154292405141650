import React, { ReactNode } from "react";
import { Spin } from "antd";
import FullPageSpinnerStyler from "../FullPageSpinnerStyler";
import CrashView from "../CrashView";
import NotFound from "./NotFound";
import { ApiError } from "../helpers/eGateApi";

/**
 * View spinner properties
 */
export interface ViewSpinnerProps {
  isFetching?: boolean;
  children?: ReactNode;
  notFound?: boolean;
  error?: ApiError | null;
}

export default function ViewSpinner(props: ViewSpinnerProps) {
  if (props.error && Object.keys(props.error).length !== 0) {
    return (
      <CrashView
        developmentError={props?.error?.errors && Object.values(props?.error?.errors)?.join(", ")}
        sentryId={props?.error?.sentryId}
      />
    );
  }
  if (props.notFound && !props.isFetching) {
    return <NotFound />;
  }
  if (!props.isFetching) {
    return <>{props.children}</>;
  }
  return (
    <FullPageSpinnerStyler className={"full-page-loader"}>
      <Spin spinning />
    </FullPageSpinnerStyler>
  );
}
