import { ROLE } from "../../Auth/useRole";
import { EntityConfig, entityFactory } from "../../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("egate-departments"),
  endpoint: "customers/departments",
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN],
  roleAdmin: [ROLE.CUSTOMER_ADMIN],
  singularName: (t) => t("eGate Department"),
  name: (t) => t("eGate Departments"),
};

export default conf;
