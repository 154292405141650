import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("contract"),
  endpoint: "contracts",
  roleView: [ROLE.CONTRACT_VIEW, ROLE.CONTRACT_ADMIN],
  roleAdmin: [ROLE.CONTRACT_ADMIN],
  singularName: (t) => t("Contract"),
  name: (t) => t("Contracts"),
  dependencies: ["user", "customer"],
  syncCustomerFilter: true,
  optimistic: true,
};

export default conf;
