import { useQuery } from "@tanstack/react-query";
import { ServerInfoDto } from "../eGate-API";
import { toJson } from "../helpers/apiHelpers";
import { linkEGate } from "../helpers/eGateApi";
import { useTranslation } from "react-i18next";

export default function useServerInfo() {
  const { i18n } = useTranslation();
  return useQuery({
    queryKey: ["server-info"],
    queryFn: () => toJson<ServerInfoDto>(fetch(linkEGate("server-info", { culture: i18n.language }))),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
