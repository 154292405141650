import { notification } from "antd";
import { ApiError } from "./eGateApi";
import { TFunction } from "i18next";

export function responseErrorNotification(error: ApiError, t: TFunction) {
  const errorDes = `${error?.detail ?? ""} ${Object.values(error?.errors ?? {})?.join(", ")}`;
  if (error.status === 403) {
    notification.error({
      message: t("You are not authorized for the operation"),
      placement: "top",
    });
  } else {
    notification.error({
      message: `${t("The form could not be submitted due to validation")} `,
      description: errorDes,

      placement: "top",
    });
  }
}
