import { Button, Form, Result } from "antd";
import { useForm } from "antd/es/form/Form";

import React from "react";
import { useTranslation } from "react-i18next";

import { Link, useNavigate } from "react-router-dom";
import LoginStyler from "./Login.style";
import { PasswordResetRequestCreateDto } from "../eGate-API";
import { fetchEGate } from "../helpers/eGateApi";
import { useToggle } from "react-use";
import LoginFooter from "./LoginFooter";
import classNames from "classnames";
import styled from "styled-components";
import SimpleTopBar from "./SimpleTopBar";
import FaIcon from "../components/FaIcon";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";

const Style = styled.div`
  .reset-text {
    width: min(600px, 90%);
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
  }
  .reset-heading {
    font-family: Manrope, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
  }
  a {
    margin-top: 3rem;
    text-align: center;
  }
  .ant-result {
    padding: 0;
  }
  .ant-result-subtitle {
    width: min(600px, 95%);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: left;

    margin-top: 30px;
  }
`;

function PasswordResetInit() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [form] = useForm();

  const [isFetching, toggleisFetching] = useToggle(false);
  const [fetched, toggleFetched] = useToggle(false);
  const [error, toggleError] = useToggle(false);

  async function initPasswordReset(values: PasswordResetRequestCreateDto) {
    try {
      toggleisFetching();
      await fetchEGate("POST", `users/password-reset`, values);
    } catch (e) {
      toggleError();
    }
    toggleFetched();
    toggleisFetching();
  }

  /**
   * Submit form callback
   * @param {} values
   */
  function handleSubmit(values: any) {
    if (isFetching) return; // Prevent multi-submit
    void initPasswordReset(values);
  }

  return (
    <LoginStyler>
      <SimpleTopBar />
      <Style className="login-content">
        {fetched ? (
          <InitResult />
        ) : (
          <Form form={form} onFinish={handleSubmit}>
            <div className="logo-wrapper">
              <h3 className={"reset-heading"}>{t("Reset password")}</h3>
            </div>
            <p className={"reset-text"}>
              {t("Please enter the email address you used to register to the eGate digi. ")}
            </p>
            <Form.Item
              name={"email"}
              noStyle
              rules={[
                {
                  required: true,
                  type: "email",
                  message: t("Please input your email!") as string,
                },
              ]}
            >
              <input disabled={isFetching} id="email" placeholder={t("E-Mail")} />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {(fi) => {
                const email = fi.getFieldValue("email");
                const emailError = fi.getFieldError("email");
                const isDisabled = !email;
                return (
                  <Button
                    className={classNames("login-button", {
                      "disabled": isDisabled,
                    })}
                    disabled={emailError.length > 0 || isFetching || isDisabled}
                    htmlType="submit"
                    id="btnResetPassword"
                    loading={isFetching}
                    title={emailError.length > 0 ? t("Invalid email") : undefined}
                    type="primary"
                  >
                    {t("Reset password")}
                  </Button>
                );
              }}
            </Form.Item>
            <Link id={"back-to-login"} to="/signin">
              {t("Back to login")}
            </Link>
          </Form>
        )}
      </Style>
      <LoginFooter />
    </LoginStyler>
  );

  function InitResult() {
    const resultStatus = error ? "error" : "success";
    const title = error ? t("Cannot initiate password reset due to server failure") : t("Password reset initiated");
    const subTitle = !error
      ? t(
          "If the email you provided is registered in our system, you should receive message with password reset instructions shortly."
        )
      : null;
    return (
      <Result
        extra={[
          <Button
            key="ok"
            className={classNames("login-button")}
            type="primary"
            onClick={() => {
              if (!error) {
                navigate("/signin");
              } else {
                toggleFetched();
                toggleError();
              }
            }}
          >
            {error ? t("Try again") : t("OK")}
          </Button>,
        ]}
        icon={<FaIcon icon={error ? faTimesCircle : faCheckCircle} />}
        status={resultStatus}
        subTitle={subTitle}
        title={title}
      />
    );
  }
}

export default PasswordResetInit;
