/**
 * Gets whether response content is 0 length
 *
 * @param response - Response to test
 * @returns True if Content-Length header is "0"
 */
export function isZeroLengthContent(response: Response): boolean {
  if (response.status === 204) return true;
  const contentType = response.headers.get("Content-Length");
  if (contentType) return contentType === "0";
  return false;
}

/**
 * Gets whether response content is JSON
 *
 * @param response - Response to test
 * @returns True if Content-Type header is "application/json"
 */
export function isJsonContent(response: Response): boolean {
  const contentType = response.headers.get("Content-Type");
  if (contentType) return contentType.indexOf("application/json") !== -1;
  return false;
}

export function toJson<T>(promise: Promise<Response | void>): Promise<T> {
  return promise.then((response: Response | void) => {
    if (response && response.ok) {
      // For zero-length, return empty object
      if (isZeroLengthContent(response)) {
        return Promise.resolve({});
      }
      // Return promise of response body as JSON
      if (isJsonContent(response)) {
        return response.json();
      }
      return Promise.resolve();
    }
    // Fail the promise
    return Promise.reject(response);
  });
}
