import React from "react";
import { Link } from "react-router-dom";
import logo from "../../image/main-logo.svg";
import logoSmall from "../../image/logo-small.svg";

/**
 * Logo properties
 */
export interface LogoProps {
  /** Is logo collapsed? */
  collapsed: boolean;
}

// @ts-ignore
const isCypress = window.Cypress !== undefined;

/**
 * Logo
 */
export default (props: LogoProps) => (
  <div className={"logo-wrapper"}>
    {props.collapsed ? (
      <Link className={"flex justify-center"} to="/app/dashboard">
        {!isCypress && <img alt="eDigi logo" height="100%" src={logoSmall} />}
      </Link>
    ) : (
      <Link className={"flex  justify-center"} to="/app/dashboard">
        {!isCypress && <img alt="eDigi logo" height="100%" src={logo} width="90px" />}
      </Link>
    )}
  </div>
);
