import styled from "styled-components";
import { emzPrimaryColor } from "../../helpers/utils";

const BoxTitle = styled.h3`
  font-size: var(--font-subtitle);
  line-height: var(--font-subtitle-height);
  color: ${emzPrimaryColor};
  font-weight: 500;
  margin: 0 0 0 0.8rem;
`;

const BoxSubTitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
`;

export { BoxTitle, BoxSubTitle };
