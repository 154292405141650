import styled from "styled-components";
import { emzPrimaryColor } from "../helpers/utils";

const LoginStyler = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  justify-content: center;
  flex-direction: column;
  ul.no-bullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .login-content {
    margin-left: auto;
    margin-right: auto;
    width: min(397px, 90%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 397px;

    @media (max-width: 992px) {
      justify-content: flex-start;
      padding-top: 80px;
    }

    .logo-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-self: center;

      img {
        margin-bottom: 30px;
      }
    }

    .ant-form-horizontal {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }

    input {
      background-color: #f7f7f7;
      border: 0;

      box-sizing: border-box;

      width: 100%;

      padding: 20px;
      border-radius: 30px;

      color: ${emzPrimaryColor} !important;

      margin-left: auto;
      margin-right: auto;
    }

    input:focus {
      color: ${emzPrimaryColor} !important;
    }

    input::placeholder {
      color: ${emzPrimaryColor} !important;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none !important;
    }

    .login-button {
      width: 100%;
      height: 50px;
      border-radius: 30px;

      border: 0;
      font-family: Manrope, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      &.disabled {
        background: #eaeaea;
        color: #888888;
      }
    }
  }
`;

export default LoginStyler;
