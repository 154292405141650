import { ContainerAddressDto, ContainerAddressListDto } from "../eGate-API";
import React from "react";

interface FullAddressProps {
  address?: ContainerAddressDto;
}

export function formatFullAddress(address?: Partial<ContainerAddressListDto>) {
  if (!address) {
    return "";
  }

  return [address.street, address.zipCode, address.city, address.country]
    .filter((i) => i && i != "" && i != " ")
    .join(", ");
}

export default function (props: FullAddressProps) {
  const address = props.address;
  if (!address) {
    return <span />;
  }
  return <span style={{ minWidth: 300 }}>{formatFullAddress(address)}</span>;
}
