import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

export const conf: EntityConfig = {
  ...entityFactory("card-reader-preset"),
  endpoint: "nfc-card-configuration-groups",
  roleView: [ROLE.CONTAINER_VIEW, ROLE.CONTAINER_ADMIN],
  roleAdmin: [ROLE.CONTAINER_ADMIN],
  dependencies: ["container"],
  singularName: (t) => t("Card reader preset"),
  name: (t) => t("Card reader presets"),
  syncCustomerFilter: true,
};

export default conf;
