import { TFunction } from "i18next";
function tranlations(t: TFunction) {
  const options = [
    { id: "sc01_emptying", name: t("ServiceCallType.sc01_emptying", "Emptying detected") },
    { id: "sc02_no_opening", name: t("ServiceCallType.sc02_no_opening", "No opening detected") },
    { id: "sc03_fill_warning", name: t("ServiceCallType.sc03_fill_warning", "Fill level warning") },
    { id: "sc04_no_closing", name: t("ServiceCallType.sc04_no_closing", "No closing") },
    {
      id: "sc05_bat_cap_warning",
      name: t("ServiceCallType.sc05_bat_cap_warning", "Battery capacity below configured percentage"),
    },
    {
      id: "sc06_no_insertion",
      name: t("ServiceCallType.sc06_no_insertion", "No insertion within configured period"),
    },
    { id: "sc07_card_destroyed", name: t("ServiceCallType.sc07_card_destroyed", "Card was destroyed") },
    {
      id: "sc08_config_bad",
      name: t("ServiceCallType.sc08_config_bad", "Serious problem with the configuration"),
    },
    { id: "sc09_battery_change", name: t("ServiceCallType.sc09_battery_change", "Battery was changed") },
    { id: "sc10_bat_volt_warning", name: t("ServiceCallType.sc10_bat_volt_warning", "Battery voltage warning") },
    {
      id: "sc11_bat_empty",
      name: t("ServiceCallType.sc11_bat_empty", "Battery empty, capacity below configured threshold"),
    },
    { id: "sc12_ping", name: t("ServiceCallType.sc12_ping", "RF ping (connection test)") },
    { id: "sc13_lock_problem", name: t("ServiceCallType.sc13_lock_problem", "Problem with the locking mechanism") },
    { id: "sc14_no_id", name: t("ServiceCallType.sc14_no_id", "Problem with device Id") },
    { id: "sc15_service_flap", name: t("ServiceCallType.sc15_service_flap", "Service flap was opened") },
    { id: "sc16_lora_ping", name: t("ServiceCallType.sc16_lora_ping", "Ping over LoRa") },
    {
      id: "sc17_gsm_sync",
      name: t(
        "ServiceCallType.sc17_gsm_sync",
        "Synchronization of the device's data via cellular communication is necessary"
      ),
    },
    { id: "sc18_fill_sensor_warning", name: t("ServiceCallType.sc18_fill_sensor_warning", "Fill sensor warning") },
    { id: "sc19_fill_protection", name: t("ServiceCallType.sc19_fill_protection", "Fill protection") },
    { id: "sc20_ai_battery_low", name: t("ServiceCallType.sc20_ai_battery_low", "AI battery low") },
    { id: "sc21_ai_energy", name: t("ServiceCallType.sc21_ai_energy", "AI energy") },
    { id: "sc22_ai_battery_empty", name: t("ServiceCallType.sc22_ai_battery_empty", "AI battery empty") },
    { id: "sc23_ai_missing_data", name: t("ServiceCallType.sc23_ai_missing_data", "AI missing data") },
    { id: "sc24_ai_suspicious_data", name: t("ServiceCallType.sc24_ai_suspicious_data", "AI suspicious data") },
  ];
  return {
    translation: (key?: string) => t(`ServiceCallType.${key}`),
    options: options,
    optionsFilter: [
      { id: "none", name: t("ServiceCallType.none", "None") },
      { id: "any", name: t("ServiceCallType.any", "Any") },
      ...options,
    ],
  };
}
export default tranlations;
export function ServiceCallFilterTranslations(t: TFunction) {
  const d = tranlations(t);
  return {
    translation: (key?: string) => t(`ServiceCallType.${key}`),
    options: d.optionsFilter,
  };
}
