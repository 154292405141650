import { ValueSelectItem } from "../../components/ValueSelect";
import { MemberItem } from "../../components/MemberItem";
import ValueSelectInfinite from "../../components/ValueSelectInfinite";
import { useTranslation } from "react-i18next";
import useEntity from "../../hooks/useEntity";
import { CustomerListDto } from "../../eGate-API";
import Entities from "../../Entities";

export interface Props {
  onChange?: (item: MemberItem | undefined) => void;
  value?: any;
  disabled?: boolean;
  id?: string;
  autoFocus?: boolean;
  parentId?: number;
  excludeItems?: ValueSelectItem[];
  warning?: boolean;
  warningText?: string;
  autoFill?: boolean;
  defaultOpen?: boolean;
  all?: boolean;
}

const GlobalCustomerSelectField = (props: Props) => {
  const { t } = useTranslation();
  const { infiniteQuery } = useEntity<CustomerListDto>({
    ...Entities.customer,
    disableHotKeys: true,
  });

  return (
    <ValueSelectInfinite
      all={props.all}
      apiFunction={(filter, initialValue, ac) => infiniteQuery({ ...filter, fulltextfield: "name" }, initialValue, ac)}
      data-cy="customer-select"
      disabled={props.disabled}
      excludeItems={props.excludeItems}
      id={props.id ?? "customer-select"}
      placeholder={props.disabled ? t("Disabled in edit") : t("Type to search")}
      style={{ width: "100%" }}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export default GlobalCustomerSelectField;
