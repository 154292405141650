import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

const conf: EntityConfig = {
  ...entityFactory("permission-group"),
  endpoint: "permission-groups",
  roleView: [ROLE.PERMISSION_GROUP_VIEW, ROLE.PERMISSION_GROUP_ADMIN],
  roleAdmin: [ROLE.PERMISSION_GROUP_ADMIN],
  singularName: (t) => t("Permission Group"),
  name: (t) => t("Permission Groups"),
  sendCulture: true,
  baseRoute: "permission-group",
};

export default conf;
