import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
export const conf: EntityConfig = {
  ...entityFactory("truck-type"),
  endpoint: "truck-types",
  roleView: [ROLE.TRUCK_VIEW, ROLE.TRUCK_ADMIN],
  roleAdmin: [ROLE.TRUCK_ADMIN],
  singularName: (t) => t("Truck type"),
  name: (t) => t("Truck types"),
  syncCustomerFilter: true,
};
export default conf;
