import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";

const conf: EntityConfig = {
  ...entityFactory("device"),
  endpoint: "devices",
  roleView: [ROLE.USER_VIEW, ROLE.USER_ADMIN],
  roleAdmin: [ROLE.USER_ADMIN],
  singularName: (t) => t("Device"),
  name: (t) => t("Devices"),
};

export default conf;
