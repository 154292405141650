import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { faHammer } from "@fortawesome/pro-light-svg-icons";
import FaIcon from "../components/FaIcon";
import React from "react";

const conf: EntityConfig = {
  ...entityFactory("service-cockpit"),
  endpoint: "service-dashboard",
  roleView: [ROLE.CUSTOMER_VIEW, ROLE.CUSTOMER_ADMIN, ROLE.CONTAINER_ADMIN, ROLE.CONTAINER_VIEW, ROLE.APP_SERVICE_USER],
  roleAdmin: [ROLE.CUSTOMER_ADMIN],
  singularName: (t) => t("Service cockpit"),
  name: (t) => t("Service cockpit"),
  icon: <FaIcon icon={faHammer} size={"lg"} />,
  syncCustomerFilter: true,
};
export default conf;
