import { Popover } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

interface FieldDescriptionProps {
  label?: React.ReactNode;
  description: string;
  className?: string;
}

const Style = styled(Popover)`
  &:hover {
    color: rgba(153, 153, 153, 0.5) !important;
    cursor: pointer;
  }
`;

export default function FieldDescription(props: FieldDescriptionProps) {
  return (
    <Style
      content={() => <div style={{ width: "300px", whiteSpace: "pre-line" }}>{props.description}</div>}
      title={props.label}
      trigger={"hover"}
    >
      <FontAwesomeIcon className={props.className} color={"#999999"} icon={faQuestionCircle} size={"lg"} />
    </Style>
  );
}
