import React, { useState } from "react";

import styled from "styled-components";
import FaIcon from "../components/FaIcon";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FormFieldProps } from "../components/FormFields/FormFieldProps";

const Style = styled.div`
  width: 100%;
  input {
    width: 100%;
  }
  .anticon {
    margin-left: -70px !important;
  }
`;

export default function (props: Partial<FormFieldProps>) {
  const [type, setType] = useState<"password" | "text">("password");

  const icon = type == "text" ? faEyeSlash : faEye;

  return (
    <Style>
      <input
        autoComplete={props.autocomplete}
        disabled={props.disabled}
        id={props.id}
        placeholder={props.placeholder}
        type={type}
        value={props.value}
        onChange={props.onChange}
      />
      <FaIcon icon={icon} size={"lg"} onClick={() => setType(() => (type == "text" ? "password" : "text"))} />
    </Style>
  );
}
