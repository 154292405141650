import { EntityConfig, entityFactory } from "../../hooks/useEntity";
import dayjs from "dayjs";
import { ROLE } from "../../Auth/useRole";
import { rangeFilterUTC } from "../../helpers/utils";

export const conf: EntityConfig = {
  ...entityFactory("issue-charts"),
  endpoint: "reports/issue-reports-daily-count",
  roleView: [ROLE.ISSUE_VIEW, ROLE.ISSUE_ADMIN],
  roleAdmin: [ROLE.ISSUE_ADMIN],
  baseRoute: "issue-charts",
  singularName: (t) => t("Issues charts"),
  name: (t) => t("Issues charts"),
  initialFilter: {
    from: dayjs().startOf("month").subtract(3, "months").format(),
    to: dayjs().endOf("month").format(),
  },
  filterFormatter: rangeFilterUTC,
  syncCustomerFilter: true,
};

export default conf;
